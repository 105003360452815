import { captureException } from '@sentry/browser'
import capitalize from 'lodash/capitalize'
import find from 'lodash/find'

import { AMAZON_ITEMS, WALMART_ITEMS } from 'const/navigation'
import { getPage } from 'helpers/pages'

const getProductForSegment = (appType: string): string => {
    if (appType === 'default') {
        return 'Downstream'
    }

    // Capitalize first letter
    return capitalize(appType)
}

const getCategory = (page: string): string => {
    if (!page) {
        return ''
    }
    const amazon_subpage = find(AMAZON_ITEMS, { page })
    if (amazon_subpage) {
        return 'Amazon'
    }
    const walmart_subpage = find(WALMART_ITEMS, { page })
    if (walmart_subpage) {
        return 'Walmart'
    }
    return page
}

const filterIdentifyTraits = (
    params: { [key: string]: any } = {}
): { [key: string]: string } => {
    const allowedAttributes = [
        'id',
        'email',
        'languageBrowser',
        'languageApp',
        'mostRecentFullStorySession',
        'sfdcId',
        'name',
        'externalIds',
    ]

    // Return the object with allowed attributes only
    return allowedAttributes.reduce((o, key) => {
        if (key in params) {
            Object.assign(o, { [key]: params[key] })
        }

        return o
    }, {})
}

const safeSegmentCall = (callback: () => void): void => {
    try {
        callback()
    } catch (e) {
        captureException(e)
    }
}

export const clearSegmentTraits = (): void => {
    safeSegmentCall(() => {
        const traitsKey =
            window.analytics?.options?.user?.localStorage?.key ||
            'ajs_user_traits'

        localStorage.removeItem(traitsKey)
    })
}

const sendSegmentIdentify = (
    gilId: string,
    userId: string,
    extraProps: object
): void => {
    if (!gilId || !window.analytics) {
        return
    }
    safeSegmentCall(() => {
        const getFullStorySessionUrl = window.FS?.getCurrentSessionURL
        window.analytics.identify(
            gilId,
            filterIdentifyTraits({
                id: gilId,
                ...extraProps,
                languageBrowser: window.navigator?.language,
                mostRecentFullStorySession:
                    typeof getFullStorySessionUrl === 'function'
                        ? getFullStorySessionUrl()
                        : undefined,
                externalIds: {
                    id: parseInt(userId, 10),
                    type: 'ds_user_id',
                    collection: 'users',
                    encoding: 'none',
                },
            })
        )
    })
}

const sendSegmentTrackEvent = (eventName: string, extraProps: any): void => {
    if (eventName && !!window.analytics) {
        window.analytics.track(eventName, extraProps)
    }
}

export const sendSegmentPageEvent = (
    path: string,
    prevPath?: string,
    appType = 'default'
): void => {
    safeSegmentCall(() => {
        const pathData = getPage(path)
        let prevPathData = null

        if (prevPath) {
            const prevPathIsAUrl = prevPath.includes('http')
            prevPathData = prevPathIsAUrl ? getPage(prevPath).page : prevPath
        }

        if (pathData && pathData.page) {
            // convertedUrl is the URL without hash so we can use some URL methods and attributes
            const convertedUrl = new URL(document.URL.replace('/#', ''))

            const { search } = convertedUrl

            const payload = {
                product: getProductForSegment(appType),
                pageName: pathData.page,
                category: getCategory(pathData.page),
                title: document.title,
                url: document.URL,
                referrer: prevPathData || document.referrer,
                path,
                search,
            }
            window.analytics?.page(payload)
        }
    })
}

export const sendSegmentTrackAndIdentifyEvents = (
    eventName: string,
    trackProps: any,
    gilId: string,
    userId: string,
    identifyProps = {}
): void => {
    sendSegmentIdentify(gilId, userId, identifyProps)
    safeSegmentCall(() => {
        sendSegmentTrackEvent(eventName, trackProps)
    })
}

export interface TrackEvent {
    destination?: string
    text?: string
    location?: string
    appType?: string
    type?: string
    [key: string]: any
}

export const sendTrackEvent = (eventName: string, event: TrackEvent): void => {
    const {
        destination,
        text,
        location,
        appType = 'default',
        type = 'button',
        ...extraProps
    } = event

    safeSegmentCall(() => {
        // Just a safety check, because if someone sends something like an event
        //  or some types of React components it can cause a circular dependency
        //  and crash the action
        sendSegmentTrackEvent(eventName, {
            destination,
            type,
            text,
            pageUrl: window.location.href,
            product: getProductForSegment(appType),
            location,
            ...extraProps,
        })
    })
}

export const sendCtaClickEvent = (event: TrackEvent): void => {
    sendTrackEvent('CTA Clicked', event)
}

export const getIdentifyProps = (
    user: any,
    gilId: string | undefined,
    sfdcId: string | null | undefined
): any => ({
    email: user?.email,
    name: `${user?.first_name} ${user?.last_name}`,
    gilId,
    sfdcId,
    userName: user?.username,
})
