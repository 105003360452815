import { useCallback } from 'react'

import { shallowEqual, useSelector } from 'react-redux'

import {
    makeResetFilters,
    makeResetLocalFilters,
    makeUpdateFilter,
    makeUpdateFilterSettings,
    makeUpdateLocalFilter,
} from 'actions/ui/shared/filter'
import {
    selectFilters,
    selectFilterSettings,
    selectLocalFilters,
} from 'selectors/ui'
import {
    FilterSettings,
    FiltersState,
    FilterValue,
    RootReduxState,
} from 'types'

import { useAction } from './useAction'

interface HookOptions {
    pagePath: string[]
    tabPath?: string[]
}

export interface UseFilterState {
    pageFilters: FiltersState
    pageLocalFilters: FiltersState
    tabFilters: FiltersState
    tabLocalFilters: FiltersState
    filterSettings: FilterSettings
}

export const useFiltersState = ({
    pagePath,
    tabPath,
}: HookOptions): UseFilterState => ({
    pageFilters: useSelector<RootReduxState, FiltersState>(
        (state: RootReduxState) => selectFilters(state, pagePath),
        shallowEqual
    ),
    pageLocalFilters: useSelector<RootReduxState, FiltersState>(
        (state: RootReduxState) => selectLocalFilters(state, pagePath),
        shallowEqual
    ),
    tabFilters: useSelector<RootReduxState, FiltersState>(
        (state) => selectFilters(state, tabPath ?? []),
        shallowEqual
    ),
    tabLocalFilters: useSelector<RootReduxState, FiltersState>(
        (state: RootReduxState) => selectLocalFilters(state, tabPath ?? []),
        shallowEqual
    ),
    filterSettings: useSelector<RootReduxState, FilterSettings>(
        (state: RootReduxState) =>
            selectFilterSettings(state, tabPath || pagePath),
        shallowEqual
    ),
})

type UpdateOptions = {
    key: string
    value?: FilterValue | number
}

export interface FilterDispatch {
    updatePageFilter: (options: UpdateOptions) => void
    updatePageLocalFilter: (options: UpdateOptions) => void
    updateTabFilter: (options: UpdateOptions) => void
    updateTabLocalFilter: (options: UpdateOptions) => void
    updateFilterSettings: (settings: FilterSettings) => void
    resetFilters: () => void
    resetLocalFilters: () => void
}

export const useFiltersDispatch = ({
    pagePath,
    tabPath,
}: HookOptions): FilterDispatch => ({
    updatePageFilter: useCallback(useAction(makeUpdateFilter(pagePath)), [
        pagePath,
    ]),
    updatePageLocalFilter: useCallback(
        useAction(makeUpdateLocalFilter(pagePath)),
        [pagePath]
    ),
    updateTabFilter: useCallback(useAction(makeUpdateFilter(tabPath)), [
        tabPath,
    ]),
    updateTabLocalFilter: useCallback(
        useAction(makeUpdateLocalFilter(tabPath)),
        [tabPath]
    ),
    updateFilterSettings: useCallback(
        useAction(makeUpdateFilterSettings(tabPath || pagePath)),
        [pagePath, tabPath]
    ),
    resetFilters: useCallback(
        useAction(makeResetFilters(tabPath || pagePath)),
        [pagePath, tabPath]
    ),
    resetLocalFilters: useCallback(
        useAction(makeResetLocalFilters(tabPath || pagePath)),
        [pagePath, tabPath]
    ),
})
