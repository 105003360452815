export const ESTIMATED_SEARCH_VOLUME_ID = 'estimated_exact_search_volume'
export const VALUE_SCORE_ID = 'value_score'
export const RELEVANCY_SCORE = 'relevancy_score'
export const SUGGESTED_BID = 'sov_keyword_suggested_bid'
export const EASE_TO_RANK_ID = 'ease_to_rank'

export const SOV_KEYWORD_TEXT_ID = 'keyword__text'
export const SOV_KEYWORD_COUNTRY = 'country'
export const TRACK_SOV_KEYWORD_ID = 'track_sov_keyword'
export const KEYWORD_RESEARCH_LIST_ITEM_ACTIONS = 'keyword_list_item_actions'
