/**
 * Helper for old widgets that define up to two metrics.
 * Before DOW-10016. Backwards compatibility.
 */
import { YAxisOptions } from 'highcharts'

import {
    Y_AXIS_COLORS,
    Y_AXIS_COLORS_BOTH,
    Y_AXIS_PRIOR_PERIOD_COLUMN_COLORS,
} from 'configuration/charts'
import { COLUMN, SPLINE_AND_COLUMN } from 'const/widgets'
import { transformToString } from 'helpers/widgets'
import {
    AutomationCapability,
    FieldMapping,
    PriorPeriodConfig,
    Widget,
} from 'types'

import {
    generateGroupedSeries,
    generateMetricSeries,
    getAxisByMetric,
    getChartType,
} from './utils'

export class MetricsDrivenSeries {
    private readonly widget: Widget

    private readonly widgetData: any[]

    private readonly widgetPriorPeriodData: any[]

    private readonly widgetMetrics: string[]

    private readonly widgetGroupBy: string[]

    private readonly useSameAxis: boolean

    private readonly automationCapabilitiesMap: Record<
        string,
        AutomationCapability
    >

    private readonly brandGroupsMap: Record<string, any>

    private readonly priorPeriodConfig: PriorPeriodConfig

    private readonly metricsConfig: FieldMapping

    constructor(
        widget: Widget,
        widgetData: any[],
        widgetPriorPeriodData: any[],
        priorPeriodConfig: PriorPeriodConfig,
        automationCapabilitiesMap: any,
        brandGroupsMap: any,
        metricsConfig: FieldMapping
    ) {
        this.widget = widget
        this.widgetData = widgetData
        this.widgetPriorPeriodData = widgetPriorPeriodData
        this.useSameAxis = widget?.use_same_metric_axis ?? false
        this.widgetMetrics = transformToString(widget.metrics) ?? []
        this.widgetGroupBy = transformToString(widget.group_by) ?? []
        this.priorPeriodConfig = priorPeriodConfig
        this.automationCapabilitiesMap = automationCapabilitiesMap
        this.brandGroupsMap = brandGroupsMap
        this.metricsConfig = metricsConfig
    }

    getYAxes = (showTitle: boolean = true): YAxisOptions[] =>
        this.widgetMetrics.map((metric, idx) => {
            const color = (): string => {
                if (this.useSameAxis) {
                    return '#888'
                }
                if (this.widgetGroupBy.length === 1) {
                    return Y_AXIS_COLORS[idx]
                }
                return ''
            }
            return getAxisByMetric({
                widget: this.widget,
                metric,
                useTitle: !this.useSameAxis && showTitle,
                color: color(),
                isRightAxis: idx === 1,
                metricsConfig: this.metricsConfig,
            })
        })

    getSeries = (): any[] => {
        const chartType = getChartType(this.widget)

        if (this.widgetGroupBy.length === 1) {
            // Only time aggregate grouping selected so it's possible
            // to have 2 metrics selected (left/right Y-axis). Map through
            // selected metrics and build a series config object for each.
            return this.widgetMetrics.flatMap((metric, idx) =>
                generateMetricSeries({
                    widget: this.widget,
                    widgetData: this.widgetData,
                    widgetPriorPeriodData: this.widgetPriorPeriodData,
                    groupedBy: this.widgetGroupBy[0],
                    metric,
                    chartType,
                    color:
                        chartType === SPLINE_AND_COLUMN
                            ? Y_AXIS_COLORS_BOTH[idx]
                            : Y_AXIS_COLORS[idx],
                    yAxisIdx: this.useSameAxis ? 0 : idx,
                    seriesIdx: idx,
                    priorPeriodConfig: this.priorPeriodConfig,
                    priorPeriodColor:
                        chartType === COLUMN
                            ? Y_AXIS_PRIOR_PERIOD_COLUMN_COLORS[idx]
                            : Y_AXIS_COLORS[idx],
                    priorPeriodBorderColor: Y_AXIS_COLORS[idx],
                    metricsConfig: this.metricsConfig,
                })
            )
        }

        if (this.widgetGroupBy.length === 2) {
            return generateGroupedSeries(
                this.widgetGroupBy,
                this.widgetMetrics[0],
                this.widget.data_source,
                this.widgetData,
                this.automationCapabilitiesMap,
                this.brandGroupsMap,
                chartType,
                this.metricsConfig,
                this.widget.color_palette
            )
        }

        return []
    }

    // eslint-disable-next-line class-methods-use-this
    getLegendOptions = (legend: any): any => legend
}
