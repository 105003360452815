import { ReactElement } from 'react'

import { Modal } from 'antd'
import { Formik, Field, ErrorMessage } from 'formik'
import { useTranslation } from 'react-i18next'
import { object, string } from 'yup'

import { fetchOrganizationsRequest } from 'actions/orgs'
import { FormikInput } from 'components/formik'
import { useCerebroApiRequest, useAction } from 'hooks'
import { createOrganization } from 'services/cerebroApi/orgScope/resourceApi'
import { Organization, CerebroResourceResponse } from 'types'
import message from 'utilities/message'

interface Props {
    visible: boolean
    closeModal: () => void
    isImpersonationMode?: boolean
}

const CreateOrganizationModal = ({
    visible,
    closeModal,
    isImpersonationMode = false,
}: Props): ReactElement => {
    const { t } = useTranslation(['common', 'navigation'])
    const makeCerebroApiRequest = useCerebroApiRequest()
    const fetchOrganizations = useAction(fetchOrganizationsRequest)
    return (
        <Formik
            enableReinitialize
            initialValues={{
                name: '',
                salesforce_id: '',
            }}
            onSubmit={async (values, { resetForm }) =>
                makeCerebroApiRequest<
                    Organization,
                    CerebroResourceResponse<Organization>
                >({
                    request: createOrganization(values),
                    onRequestSuccess: (response) => {
                        message.success(
                            t(
                                'navigation:SideMenu.CreateOrganizationModal.successMsg',
                                `Successfully create new organization named {{orgName}}.`,
                                { orgName: response.data.name }
                            )
                        )
                        resetForm()
                        closeModal()
                        fetchOrganizations()
                    },
                })
            }
            validationSchema={object().shape({
                name: string()
                    .label(t('common:organizationName', 'Organization Name'))
                    .required(),
            })}
        >
            {({ handleSubmit, resetForm, isSubmitting }) => (
                <Modal
                    open={visible}
                    onCancel={() => {
                        resetForm()
                        closeModal()
                    }}
                    onOk={() => handleSubmit()}
                    okButtonProps={{
                        disabled: isSubmitting,
                        loading: isSubmitting,
                    }}
                    cancelButtonProps={{ disabled: isSubmitting }}
                    title={t(
                        'navigation:SideMenu.CreateOrganizationModal.title',
                        'Create a New Organization'
                    )}
                    okText={t(
                        'navigation:SideMenu.CreateOrganizationModal.okText',
                        'Create Organization'
                    )}
                >
                    <div>
                        <Field
                            component={FormikInput}
                            name="name"
                            placeholder={t(
                                'common:organizationName',
                                'Organization Name'
                            )}
                            size="large"
                        />
                        <ErrorMessage
                            name="name"
                            component="div"
                            className="fg-control-text is-error"
                        />
                    </div>
                    {isImpersonationMode && (
                        <div style={{ marginTop: '10px' }}>
                            <Field
                                component={FormikInput}
                                name="salesforce_id"
                                placeholder={t(
                                    'common:organizationSalesforceID',
                                    'Salesforce Customer ID'
                                )}
                                size="large"
                            />
                        </div>
                    )}
                </Modal>
            )}
        </Formik>
    )
}

export default CreateOrganizationModal
