export const KEYWORD = 'keyword'
export const SOV_KEYWORD = 'SOV Keyword'
export const CAMPAIGN = 'campaign'
export const PRODUCT_AD = 'product_ad'
export const AD_GROUP = 'ad_group'
export const NON_KEYWORD_TARGET = 'target'
export const DSP_LINE_ITEM = 'DSP line item'
export const PRODUCT_METADATA = 'metadata'
export const AUTOMATION_TASK = 'automation_task'
export const TARGET = 'target'
export const NEGATIVE_KEYWORD = 'negative_target'

// Labels
export const PROFILE_LABELS = 'profile__labels'
export const CAMPAIGN_LABELS = 'campaign__labels'
export const PORTFOLIO_LABELS = 'portfolio__labels'
export const PRODUCT_LABELS = 'product__labels'
export const SOV_KEYWORD_LABELS = 'keyword__labels'
export const DSP_ORDER_LABELS = 'order__labels'
export const DSP_LINE_ITEM_LABELS = 'line_item__labels'

export const RESOURCE_NAME_MAPPING: { [key: string]: string } = {
    [CAMPAIGN_LABELS]: 'Campaign Labels',
    [PORTFOLIO_LABELS]: 'Portfolio Labels',
    [PROFILE_LABELS]: 'Ad Account Labels',
    [PRODUCT_LABELS]: 'Product Labels',
    [SOV_KEYWORD_LABELS]: 'Keyword Labels',
}

export const CAMPAIGN_AUTOMATIONS = 'campaign__automation'

export const BID_MULTIPLIER_TOP_OF_SEARCH =
    'campaign_bid_multiplier_top_of_search'
export const BID_MULTIPLIER_REST_OF_SEARCH =
    'campaign_bid_multiplier_rest_of_search'
export const BID_MULTIPLIER_SITE_AMAZON_BUSINESS =
    'campaign_bid_multiplier_site_amazon_business'
export const BID_MULTIPLIER_DETAIL_PAGE = 'campaign_bid_multiplier_detail'
export const BID_MULTIPLIER_OTHER = 'campaign_bid_multiplier_other'

export const BID_MULTIPLIER_PLACEMENTS: { [key: string]: string } = {
    [BID_MULTIPLIER_TOP_OF_SEARCH]: 'Top of Search',
    [BID_MULTIPLIER_REST_OF_SEARCH]: 'Rest of Search',
    [BID_MULTIPLIER_DETAIL_PAGE]: 'Product Pages',
    [BID_MULTIPLIER_SITE_AMAZON_BUSINESS]: 'Amazon Business',
    [BID_MULTIPLIER_OTHER]: 'Other',
}
