import { ReactElement } from 'react'

import { Menu } from 'antd'
import { ItemType } from 'antd/es/menu/hooks/useItems'
import classNames from 'classnames'
import isEmpty from 'lodash/isEmpty'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'

import {
    AmazonIcon,
    ExternalLinkIcon,
    LockedLockIcon,
    WalmartIcon,
} from 'components/Icons'
import { AMAZON_ITEMS, TOP_LEVEL_ITEMS, WALMART_ITEMS } from 'const/navigation'
import {
    userHasAmazonAdvertisingPermissions,
    userHasDashboardsOnlyPermissions,
    userHasWalmartAdvertisingReadPermissions,
} from 'helpers/featurePermissions'
import { getOrganizationTheme } from 'helpers/organizationTheme'
import { getCurrentPage } from 'helpers/pages'
import useFeatureFlags from 'hooks/useFeatureFlags'
import { selectIsSidebarCollapsed } from 'selectors/ui'
import { Organization, RootReduxState, UserPermission } from 'types'

import styles from './styles.scss'

interface Props {
    currentOrganization?: Organization
    hasNoIntegrations: boolean
    featurePermissions: UserPermission[]
}

const SUBMENU_POPOVER_OFFSET: [number, number] = [10, 0]

const TopNavigation = ({
    currentOrganization,
    hasNoIntegrations,
    featurePermissions,
}: Props): ReactElement => {
    const featureFlags = useFeatureFlags()

    const isSidebarCollapsed = useSelector((state: RootReduxState) =>
        selectIsSidebarCollapsed(state)
    )

    const dashboardOnlyExperience =
        userHasDashboardsOnlyPermissions(featurePermissions)
    const amazonAdPermissions =
        userHasAmazonAdvertisingPermissions(featurePermissions)
    const walmartReadPermissions =
        userHasWalmartAdvertisingReadPermissions(featurePermissions)

    const getSideMenuSelectedKeys = (): string[] => {
        const { page } = getCurrentPage(false)
        return page ? [page] : []
    }

    const theme = getOrganizationTheme(currentOrganization)
    const extraLinks = theme?.extra_links ?? []

    const topLevelItems: ItemType[] = TOP_LEVEL_ITEMS.map(
        ({ page, title, getPath, icon, visible }) =>
            visible(featurePermissions, featureFlags)
                ? {
                      key: page,
                      icon,
                      label: (
                          <Link to={getPath(featurePermissions)}>
                              {!isSidebarCollapsed && <span>{title}</span>}
                          </Link>
                      ),
                  }
                : null
    )

    const amazonItems: ItemType[] = [
        {
            key: 'amazon',
            icon: hasNoIntegrations ? <LockedLockIcon /> : <AmazonIcon />,
            label: !isSidebarCollapsed ? <span>Amazon Ads</span> : null,
            popupClassName: styles['submenu-collapsed'],
            popupOffset: SUBMENU_POPOVER_OFFSET,
            children: AMAZON_ITEMS.map(({ page, getPath, title }) => ({
                key: page,
                label: hasNoIntegrations ? (
                    title
                ) : (
                    <Link to={getPath()}>{title}</Link>
                ),
                disabled: hasNoIntegrations,
            })),
        },
    ]

    const walmartItems: ItemType[] = [
        {
            key: 'walmart',
            icon: <WalmartIcon />,
            label: !isSidebarCollapsed ? <span>Walmart Ads</span> : null,
            popupClassName: styles['submenu-collapsed'],
            popupOffset: SUBMENU_POPOVER_OFFSET,
            children: WALMART_ITEMS.map(({ page, getPath, title }) => ({
                key: page,
                label: <Link to={getPath()}>{title}</Link>,
            })),
        },
    ]

    const divider: ItemType[] = [
        {
            key: 'extraLinksDivider',
            label: <div className={styles.divider} />,
        },
    ]

    const extraItems: ItemType[] = extraLinks.map(({ src, label }, idx) => ({
        key: `extraLink_${idx}`,
        label: !isSidebarCollapsed ? (
            <a target="_blank" rel="noopener noreferrer" href={src}>
                <ExternalLinkIcon />
                {!isSidebarCollapsed && <span>{label}</span>}
            </a>
        ) : (
            label
        ),
    }))

    const menuItems: ItemType[] = [
        ...topLevelItems,
        ...(!dashboardOnlyExperience && amazonAdPermissions ? amazonItems : []),
        ...(!dashboardOnlyExperience && walmartReadPermissions
            ? walmartItems
            : []),
        ...(!isEmpty(extraLinks) ? divider : []),
        ...extraItems,
    ]

    return (
        <Menu
            mode="inline"
            theme="dark"
            items={menuItems}
            defaultSelectedKeys={['1']}
            defaultOpenKeys={['amazon']}
            selectedKeys={getSideMenuSelectedKeys()}
            className={classNames(styles['sider-menu'], {
                [styles['sidebar-open']]: !isSidebarCollapsed,
            })}
        />
    )
}

export default TopNavigation
