import cloneDeep from 'lodash/cloneDeep'
import set from 'lodash/fp/set'
import get from 'lodash/get'
import { AnyAction, CombinedState, combineReducers } from 'redux'
import { ReduxCompatibleReducer } from 'redux-actions'
import { PersistMigrate } from 'redux-persist'

import {
    AD_ACCOUNT_PAGE,
    AD_ACCOUNTS_SUMMARY_PAGE,
    AD_GROUP_PAGE,
    ALERT_CREATION_PAGE,
    ALERT_PAGE,
    ALERTS_SUMMARY_PAGE,
    AUTH_ORGANIZATION_PAGE,
    AUTOMATION_PAGE,
    AUTOMATION_PROFILE_PAGE,
    BULK_UPDATES_PAGE,
    CAMPAIGN_PAGE,
    CAMPAIGNS_SUMMARY_PAGE,
    DASHBOARD_PAGE,
    DASHBOARD_PRINT_PAGE,
    DASHBOARDS_SUMMARY_PAGE,
    DSP_ADVERTISER_PAGE,
    DSP_LINE_ITEM_PAGE,
    DSP_ORDER_PAGE,
    DSP_SUMMARY_PAGE,
    EVENTS_SUMMARY_PAGE,
    HOME_PAGE,
    KEYWORD_PAGE,
    KEYWORD_RESEARCH_LIST_DETAIL_PAGE,
    KEYWORD_RESEARCH_LISTS_PAGE,
    KEYWORD_RESEARCH_PAGE,
    LABEL_PAGE,
    LABELS_SUMMARY_PAGE,
    ORGANIZATION_GROUP_PAGE,
    ORGANIZATION_PAGE,
    PORTFOLIO_PAGE,
    PORTFOLIOS_SUMMARY_PAGE,
    PRODUCT_AD_PAGE,
    PRODUCT_PAGE,
    PRODUCTS_SUMMARY_PAGE,
    REPORTS_SUMMARY_PAGE,
    RULEBOOK_PAGE,
    SEGMENT_CREATION_PAGE,
    SEGMENT_EDIT_PAGE,
    SEGMENT_PAGE,
    SEGMENTS_SUMMARY_PAGE,
    SOV_KEYWORD_PAGE,
    SOV_KEYWORD_SEARCH_RESULT_PAGE,
    SOV_KEYWORDS_SUMMARY_PAGE,
    TARGETING_SUMMARY_PAGE,
    WALMART_AD_GROUP_PAGE,
    WALMART_CAMPAIGN_PAGE,
    WALMART_CAMPAIGNS_SUMMARY_PAGE,
    WALMART_ITEMS_SUMMARY_PAGE,
    WALMART_KEYWORDS_SUMMARY_PAGE,
} from 'const/pages'

import adGroupPage from './adGroupPage'
import alertCreationPage from './alertCreationPage'
import alertPage from './alertPage'
import alertsSummaryPage from './alertsSummaryPage'
import app from './app'
import authOrganizationPage from './authOrganizationPage'
import automationPage from './automationPage'
import automationProfilePage from './automationProfilePage'
import brandPage from './brandPage'
import brandsSummary from './brandsSummary'
import bulkUpdatesPage from './bulkUploadsPage'
import campaignPage from './campaignPage'
import campaignsSummary from './campaignsSummary'
import dashboardPage from './dashboardPage'
import dashboardPrintPage from './dashboardPrintPage'
import dashboardsSummary from './dashboardsSummary'
import dspAdvertiserPage from './dspAdvertiserPage'
import dspLineItemPage from './dspLineItemPage'
import dspOrderPage from './dspOrderPage'
import dspSummary from './dspSummary'
import eventsSummary from './eventsSummary'
import homePage from './homePage'
import keywordPage from './keywordPage'
import labelPage from './labelPage'
import labelsSummaryPage from './labelsSummary'
import organizationGroupPage from './organizationGroupPage'
import organizationPage from './organizationPage'
import portfolioPage from './portfolioPage'
import portfoliosSummary from './portfoliosSummary'
import productAdPage from './productAdPage'
import productPage from './productPage'
import productsSummary from './productsSummary'
import reportsSummary from './reportsSummary'
import rulebookPage from './rulebookPage'
import targetingSummary from './targetingSummary'
import walmartAdGroupPage from './walmartAdGroupPage'
import walmartCampaignPage from './walmartCampaignPage'
import walmartCampaignsSummary from './walmartCampaignsSummary'
import walmartItemsSummary from './walmartItemsSummary'
import walmartKeywordsSummary from './walmartKeywordsSummary'

const staticReducerKeys = [
    'app',
    AD_ACCOUNTS_SUMMARY_PAGE,
    AD_ACCOUNT_PAGE,
    AD_GROUP_PAGE,
    ALERTS_SUMMARY_PAGE,
    ALERT_CREATION_PAGE,
    ALERT_PAGE,
    AUTH_ORGANIZATION_PAGE,
    AUTOMATION_PAGE,
    AUTOMATION_PROFILE_PAGE,
    BULK_UPDATES_PAGE,
    CAMPAIGNS_SUMMARY_PAGE,
    CAMPAIGN_PAGE,
    DASHBOARDS_SUMMARY_PAGE,
    DASHBOARD_PAGE,
    DASHBOARD_PRINT_PAGE,
    DSP_ADVERTISER_PAGE,
    DSP_LINE_ITEM_PAGE,
    DSP_ORDER_PAGE,
    DSP_SUMMARY_PAGE,
    EVENTS_SUMMARY_PAGE,
    HOME_PAGE,
    KEYWORD_PAGE,
    LABEL_PAGE,
    LABELS_SUMMARY_PAGE,
    ORGANIZATION_GROUP_PAGE,
    ORGANIZATION_PAGE,
    PORTFOLIO_PAGE,
    PORTFOLIOS_SUMMARY_PAGE,
    PRODUCT_AD_PAGE,
    PRODUCT_PAGE,
    PRODUCTS_SUMMARY_PAGE,
    REPORTS_SUMMARY_PAGE,
    RULEBOOK_PAGE,
    TARGETING_SUMMARY_PAGE,
    WALMART_AD_GROUP_PAGE,
    WALMART_CAMPAIGN_PAGE,
    WALMART_CAMPAIGNS_SUMMARY_PAGE,
    WALMART_ITEMS_SUMMARY_PAGE,
    WALMART_KEYWORDS_SUMMARY_PAGE,
] as const

type StaticReducerKey = (typeof staticReducerKeys)[number]

export const dynamicReducerKeys = [
    KEYWORD_RESEARCH_PAGE,
    KEYWORD_RESEARCH_LISTS_PAGE,
    KEYWORD_RESEARCH_LIST_DETAIL_PAGE,
    SEGMENT_CREATION_PAGE,
    SEGMENT_EDIT_PAGE,
    SEGMENT_PAGE,
    SEGMENTS_SUMMARY_PAGE,
    SOV_KEYWORD_PAGE,
    SOV_KEYWORD_SEARCH_RESULT_PAGE,
    SOV_KEYWORDS_SUMMARY_PAGE,
] as const

export type DynamicReducerKey = (typeof dynamicReducerKeys)[number]

export interface DynamicReducerOptions {
    migrations?: PersistMigrate
    version?: number
}

// TODO: Improve typing here - the reducers here are typed differently
// Ideally we should return ReduxCompatibleReducer<any, AnyAction>
const staticReducers: Record<
    StaticReducerKey,
    ReduxCompatibleReducer<any, any>
> = {
    app,
    [AD_ACCOUNTS_SUMMARY_PAGE]: brandsSummary,
    [AD_ACCOUNT_PAGE]: brandPage,
    [AD_GROUP_PAGE]: adGroupPage,
    [ALERTS_SUMMARY_PAGE]: alertsSummaryPage,
    [ALERT_CREATION_PAGE]: alertCreationPage,
    [ALERT_PAGE]: alertPage,
    [AUTH_ORGANIZATION_PAGE]: authOrganizationPage,
    [AUTOMATION_PAGE]: automationPage,
    [AUTOMATION_PROFILE_PAGE]: automationProfilePage,
    [BULK_UPDATES_PAGE]: bulkUpdatesPage,
    [CAMPAIGNS_SUMMARY_PAGE]: campaignsSummary,
    [CAMPAIGN_PAGE]: campaignPage,
    [DASHBOARDS_SUMMARY_PAGE]: dashboardsSummary,
    [DASHBOARD_PAGE]: dashboardPage,
    [DASHBOARD_PRINT_PAGE]: dashboardPrintPage,
    [DSP_ADVERTISER_PAGE]: dspAdvertiserPage,
    [DSP_LINE_ITEM_PAGE]: dspLineItemPage,
    [DSP_ORDER_PAGE]: dspOrderPage,
    [DSP_SUMMARY_PAGE]: dspSummary,
    [EVENTS_SUMMARY_PAGE]: eventsSummary,
    [HOME_PAGE]: homePage,
    [KEYWORD_PAGE]: keywordPage,
    [LABELS_SUMMARY_PAGE]: labelsSummaryPage,
    [LABEL_PAGE]: labelPage,
    [ORGANIZATION_GROUP_PAGE]: organizationGroupPage,
    [ORGANIZATION_PAGE]: organizationPage,
    [PORTFOLIOS_SUMMARY_PAGE]: portfoliosSummary,
    [PORTFOLIO_PAGE]: portfolioPage,
    [PRODUCTS_SUMMARY_PAGE]: productsSummary,
    [PRODUCT_AD_PAGE]: productAdPage,
    [PRODUCT_PAGE]: productPage,
    [REPORTS_SUMMARY_PAGE]: reportsSummary,
    [RULEBOOK_PAGE]: rulebookPage,
    [TARGETING_SUMMARY_PAGE]: targetingSummary,
    [WALMART_AD_GROUP_PAGE]: walmartAdGroupPage,
    [WALMART_CAMPAIGNS_SUMMARY_PAGE]: walmartCampaignsSummary,
    [WALMART_CAMPAIGN_PAGE]: walmartCampaignPage,
    [WALMART_ITEMS_SUMMARY_PAGE]: walmartItemsSummary,
    [WALMART_KEYWORDS_SUMMARY_PAGE]: walmartKeywordsSummary,
}

const asyncReducers = {} as Record<
    DynamicReducerKey,
    ReduxCompatibleReducer<any, AnyAction>
>

export const getAllAsyncReducerKeys = (): readonly DynamicReducerKey[] => {
    return dynamicReducerKeys
}

export const injectUiReducer = (
    key: DynamicReducerKey,
    asyncReducer: ReduxCompatibleReducer<any, AnyAction>
): void => {
    if (!getAllAsyncReducerKeys().includes(key)) {
        throw new Error(
            `Unexpected reducer key. ${key} is not in the list of dynamically loaded reducers.`
        )
    }
    asyncReducers[key] = asyncReducer
}

export const getDefaultState = (): CombinedState<
    Record<
        StaticReducerKey | DynamicReducerKey,
        ReduxCompatibleReducer<any, any>
    >
> => {
    const combinedReducers = combineReducers({
        ...staticReducers,
        ...asyncReducers,
    })
    return combinedReducers(undefined, {} as any)
}

// Save a copy of the initial default state for resets, the default state may be updated by redux-persist
let pristineUiStateClone = cloneDeep(getDefaultState())

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const getPristineUiStateClone = () => pristineUiStateClone

export const updatePristineUiStateClone = (
    state: any,
    path: string[]
): void => {
    const firstStateSeen = get(state, path)
    if (firstStateSeen) {
        pristineUiStateClone = set(path, firstStateSeen, pristineUiStateClone)
    }
}

export const buildUiReducer = (): ReduxCompatibleReducer<any, AnyAction> => {
    return combineReducers({ ...staticReducers, ...asyncReducers })
}
