import { captureException } from '@sentry/browser'
import Cookies from 'js-cookie'

const planHatCookieKey = '_plantrack'

export const stopPlanHat = (): void => {
    try {
        if (!window.plantrack) {
            return
        }
        if (Cookies.get(planHatCookieKey)) {
            Cookies.remove(planHatCookieKey)
        }
    } catch (e) {
        captureException(e)
    }
}

export const identifyUserInPlanHat = (
    orgSalesforceId?: string | null,
    userGilId?: string
): void => {
    if (!window.plantrack || !orgSalesforceId || !userGilId) {
        // do not identify users with no orgSalesforceId or userGilId
        stopPlanHat()
        return
    }
    try {
        window.plantrack?.identify(userGilId, {
            companyExternalId: orgSalesforceId,
        })
    } catch (e) {
        captureException(e)
    }
}
