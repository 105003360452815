import { all } from 'redux-saga/effects'

import './keywords'
import './keywordSearchTerms'
import './negativeKeywords'
import './productAds'
import './placements'
import './targets'
import './targetSearchTerms'
import './page'
import './budget'
import './products'

import automationTabWatcher from './automation'

export default function* campaignPageWatcher() {
    yield all([
        // Automation Tab
        automationTabWatcher(),
    ])
}
