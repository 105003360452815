import { all } from 'redux-saga/effects'

// Pages
import appWatcher from './app'
import campaignPageWatcher from './campaign'
import dashboardPageWatcher from './dashboard'
import organizationWatcher from './organization'
import organizationGroupPageWatcher from './organizationGroup'
import productsSummaryWatcher from './productsSummary'
import sharedWatcher from './shared'

// No Watchers but needs to be imported to register shared sagas
import './adGroup'
import './alert'
import './alertsSummary'
import './authOrganizationPage'
import './automation'
import './automationProfile'
import './brand'
import './brandsSummary'
import './bulkUpdates'
import './dashboardPrint'
import './dashboardsSummary'
import './dspAdvertiser'
import './dspLineItem'
import './dspOrder'
import './dspSummary'
import './home'
import './keyword'
import './targetingSummary'
import './label'
import './labelsSummary'
import './portfolio'
import './portfoliosSummary'
import './product'
import './productAd'
import './reportsSummary'
import './rulebook'
import './walmartAdGroup'
import './walmartCampaign'
import './walmartCampaignsSummary'
import './walmartKeywordsSummary'
import './walmartItemsSummary'

export default function* uiSaga() {
    yield all([
        appWatcher(),

        // Pages
        campaignPageWatcher(),
        dashboardPageWatcher(),
        organizationGroupPageWatcher(),
        organizationWatcher(),
        productsSummaryWatcher(),
        sharedWatcher(),
    ])
}
