import { ReactElement } from 'react'

import { Alert, Button, Checkbox, Divider, Modal, Typography } from 'antd'
import { Field, FieldProps, Formik } from 'formik'
import map from 'lodash/map'

import { CreateOrganizationModal } from 'components/CreateResourceModal'
import { PlusIcon } from 'components/Icons'
import OrganizationGroupSearchSelect from 'components/SearchSelect/OrganizationGroupSearchSelect/OrganizationGroupSearchSelect'
import {
    deserializeOrgGroup,
    SerializableOrgGroup,
    serializeOrgGroup,
} from 'components/SearchSelect/OrganizationGroupSearchSelect/serializableOrgGroup'
import SerializableOrgGroupDisplay from 'components/SearchSelect/OrganizationGroupSearchSelect/SerializableOrgGroupDisplay'
import { useModal } from 'hooks'
import useLocalStorage from 'hooks/useLocalStorage'
import {
    AutomationCapabilitiesMap,
    Organization,
    OrganizationGroup,
} from 'types'

import { ImpersonationButtonToggle } from './ImpersonationButtonToggle'

const { Title } = Typography

interface Props {
    modalVisible: boolean
    closeModal: () => void
    currentOrganization: Organization
    currentOrganizationGroup: OrganizationGroup
    handleChangeOrganizationGroup: (
        organizationId: string,
        organizationGroupId: string
    ) => void
    automationCapabilities: AutomationCapabilitiesMap
    hiddenAutomationCapabilities: string[]
    handleChangeHiddenAutomationCapabilities: (
        hiddenAutomations: string[]
    ) => void
}

const ImpersonationModal = ({
    modalVisible,
    closeModal,
    currentOrganization,
    currentOrganizationGroup,
    handleChangeOrganizationGroup,
    automationCapabilities,
    hiddenAutomationCapabilities,
    handleChangeHiddenAutomationCapabilities,
}: Props): ReactElement => {
    const {
        isModalVisible: isCreateOrgModalVisible,
        toggleModalVisible: toggleCreateOrgModal,
    } = useModal()
    const [pinnedOrganizations, setPinnedOrganizations] = useLocalStorage<
        SerializableOrgGroup[]
    >('PinnedImpersonationOrganizations', [])

    const handlePinOrganization = (newPin: SerializableOrgGroup): void => {
        setPinnedOrganizations((prev) =>
            [...prev, newPin].toSorted((a, b) => {
                if (a.orgId < b.orgId) {
                    return -1
                }
                if (a.orgId > b.orgId) {
                    return 1
                }
                if ((a.label ?? '') < (b.label ?? '')) {
                    return -1
                }
                if ((a.label ?? '') > (b.label ?? '')) {
                    return 1
                }
                return 0
            })
        )
    }

    const handleUnpinOrganization = (unpin: SerializableOrgGroup): void => {
        setPinnedOrganizations((prev) =>
            prev.filter((pinned) => pinned.orgGroupId !== unpin.orgGroupId)
        )
    }

    return (
        <>
            <Formik
                initialValues={{
                    orgGroup: serializeOrgGroup({
                        orgId: currentOrganization.id,
                        orgGroupId: currentOrganizationGroup.id,
                    }),
                    hiddenAutomations: hiddenAutomationCapabilities,
                }}
                onSubmit={({ orgGroup, hiddenAutomations }) => {
                    const { orgId, orgGroupId } = deserializeOrgGroup(orgGroup)
                    handleChangeOrganizationGroup(orgId, orgGroupId)
                    handleChangeHiddenAutomationCapabilities(hiddenAutomations)
                    closeModal()
                }}
            >
                {({ setFieldValue, handleSubmit }) => (
                    <Modal
                        title="Impersonation Mode Settings"
                        open={modalVisible}
                        onCancel={closeModal}
                        onOk={(_) => handleSubmit()}
                    >
                        <div className="d-flex flex-column align-items-start">
                            <Alert
                                message={
                                    <>
                                        While operating in Impersonation Mode,
                                        you are accessing real customer data in
                                        production. Changes made to{' '}
                                        <b>anything</b> in Impersonation Mode
                                        will be <b>live in production</b>. Use
                                        extreme caution when making changes.{' '}
                                        <ImpersonationButtonToggle />.
                                    </>
                                }
                                type="error"
                                className="mb-3"
                            />
                            <Title level={4} className="mb-1">
                                Organization Group to Assume
                            </Title>
                            <Field name="orgGroup">
                                {({
                                    field,
                                    form: {
                                        values: { orgGroup },
                                    },
                                }: FieldProps) => (
                                    <OrganizationGroupSearchSelect
                                        className="mt-2"
                                        onChange={(id) => {
                                            setFieldValue(field.name, id)
                                        }}
                                        value={orgGroup}
                                        prefetchRecordLimit={100} // Prefetch more records for impersonation modal
                                        defaultOptions={
                                            currentOrganizationGroup &&
                                            currentOrganization
                                                ? [
                                                      {
                                                          ...currentOrganizationGroup,
                                                          // merge in `organization`
                                                          // because it is not persisted
                                                          // with organization groups in redux
                                                          organization:
                                                              currentOrganization,
                                                      },
                                                  ]
                                                : []
                                        }
                                        pinnedOrganizations={
                                            pinnedOrganizations
                                        }
                                        onPinOrganizationGroup={
                                            handlePinOrganization
                                        }
                                    />
                                )}
                            </Field>
                            {pinnedOrganizations.length > 0 && (
                                <Title level={5} className="mt-3 mb-1">
                                    Your Pinned Organization Groups
                                </Title>
                            )}
                            {pinnedOrganizations.map((serializable) => (
                                <div
                                    className="w-100"
                                    key={serializable.orgGroupId}
                                >
                                    <Button
                                        className="w-100"
                                        type="link"
                                        onClick={() => {
                                            setFieldValue(
                                                'orgGroup',
                                                serializeOrgGroup(serializable)
                                            )
                                        }}
                                    >
                                        <SerializableOrgGroupDisplay
                                            serializable={serializable}
                                            onUnpin={handleUnpinOrganization}
                                            showAction
                                            isPinned
                                        />
                                    </Button>
                                </div>
                            ))}
                            <Button
                                type="link"
                                icon={<PlusIcon />}
                                className="pl-0 mt-3"
                                onClick={() => {
                                    closeModal()
                                    toggleCreateOrgModal()
                                }}
                            >
                                Create New Organization
                            </Button>
                            <Divider className="mt-3" />
                            <h3>Hidden Automation Capabilities</h3>
                            <Field name="hiddenAutomations">
                                {({
                                    field,
                                    form: {
                                        values: { hiddenAutomations },
                                    },
                                }: FieldProps) => (
                                    <Checkbox.Group
                                        options={map(
                                            automationCapabilities,
                                            (capability, capabilityId) => ({
                                                label: capability.name,
                                                value: capabilityId,
                                            })
                                        )}
                                        defaultValue={hiddenAutomations}
                                        onChange={(selectedOptions) => {
                                            setFieldValue(
                                                field.name,
                                                selectedOptions
                                            )
                                        }}
                                    />
                                )}
                            </Field>
                        </div>
                    </Modal>
                )}
            </Formik>
            <CreateOrganizationModal
                visible={isCreateOrgModalVisible}
                closeModal={toggleCreateOrgModal}
                isImpersonationMode
            />
        </>
    )
}

export default ImpersonationModal
