import { useCallback, useState } from 'react'

type UseLocalStorageReturn<T> = [T, (value: T | ((prev: T) => T)) => void]

const useLocalStorage = <T>(
    key: string,
    defaultValue: T
): UseLocalStorageReturn<T> => {
    const storedItem = localStorage.getItem(key)
    const [localItem, setLocalItem] = useState(
        storedItem ? (JSON.parse(storedItem) as T) : defaultValue
    )

    const setItem = useCallback(
        (value: T | ((prev: T) => T)): void => {
            const newValue =
                value instanceof Function ? value(localItem) : value
            localStorage.setItem(key, JSON.stringify(newValue))
            setLocalItem(newValue)
        },
        [key, localItem]
    )

    return [localItem, setItem]
}

export default useLocalStorage
