import { YAxisOptions } from 'highcharts'
import groupBy from 'lodash/groupBy'
import meanBy from 'lodash/meanBy'
import orderBy from 'lodash/orderBy'
import truncate from 'lodash/truncate'

import { DATA_SOURCES } from 'configuration/widgets'
import { ColorPalette } from 'const/colors'
import { AREA, COLUMN, SPLINE, SPLINE_AND_COLUMN } from 'const/widgets'
import { getYAxisOptions } from 'helpers/chart'
import { getSeriesColor, getSeriesName } from 'helpers/charts'
import { formatNumber } from 'helpers/formatting'
import { elementToString } from 'helpers/utilities'
import {
    Field,
    FieldMapping,
    MetricType,
    NonTextWidgetDataSourceKey,
    PriorPeriodConfig,
    Widget,
} from 'types'
import moment from 'utilities/moment'

const isRankMetric = (metricField?: Field<any>): boolean =>
    metricField?.metricOptions?.type === 'rank'

export const isPercentMetric = (metricType?: MetricType): boolean => {
    return metricType === 'percentage' || metricType === 'percentage_as_is'
}

// For stacked area chart with a percentage metric endOnTick should be
// false to avoid showing percentages above 100%
const endOnTick = (widget: Widget, metricType?: MetricType): boolean =>
    !(widget.stacked && isPercentMetric(metricType))

export const getFinalChartType = (
    originalChartType?: string,
    stacked?: boolean
): string => {
    if (!originalChartType || originalChartType === SPLINE) {
        // switch to AREA for stacked spline charts
        return stacked ? AREA : SPLINE
    }
    return originalChartType
}

export const getChartType = (widget: Widget): string =>
    getFinalChartType(widget.chart_type, widget.stacked)

type GetAxisByMetricInput = {
    widget: Widget
    metric: string
    useTitle: boolean
    color: string
    isRightAxis: boolean
    metricsConfig: FieldMapping
    verticalTitle?: boolean
    title?: string
    reserveTitleSpace?: boolean
}

export const getAxisByMetric = ({
    widget,
    metric,
    useTitle,
    color,
    isRightAxis,
    metricsConfig,
    verticalTitle = false,
    title,
    reserveTitleSpace = false,
}: GetAxisByMetricInput): YAxisOptions => {
    const config = metricsConfig[metric]
    const titleText = useTitle
        ? title ?? elementToString(config?.name)
        : undefined
    const isReversed = isRankMetric(config)
    const valueFormatter = (value: number | string): string => {
        let formattedValue = value

        if (widget.stacked && widget.stacked_type === 'percent') {
            formattedValue = `${value}%`
        } else if (config) {
            formattedValue = formatNumber(
                value,
                config.metricOptions?.shortFormat
            )
        }
        return `<span style="color: ${color};">${formattedValue}</span>`
    }
    return getYAxisOptions({
        titleText,
        color,
        valueFormatter,
        endOnTick: endOnTick(widget, config?.metricOptions?.type),
        isRightAxis,
        isReversed,
        verticalTitle,
        reserveTitleSpace,
    })
}

type GenerateMetricSeriesInput = {
    widget: Widget
    widgetData: any[]
    widgetPriorPeriodData: any[]
    groupedBy: string
    metric: string
    chartType: string
    color: string
    yAxisIdx: number
    seriesIdx: number
    priorPeriodConfig: PriorPeriodConfig
    priorPeriodColor: string
    priorPeriodBorderColor: string
    metricsConfig: FieldMapping
    side?: string
    legendItemBorderColor?: string
    markerSymbol?: string
}

export const generateMetricSeries = ({
    widget,
    widgetData,
    widgetPriorPeriodData,
    groupedBy,
    metric,
    chartType,
    color,
    yAxisIdx,
    seriesIdx,
    priorPeriodConfig,
    priorPeriodColor,
    priorPeriodBorderColor,
    metricsConfig,
    side,
    legendItemBorderColor,
    markerSymbol,
}: GenerateMetricSeriesInput): any[] => {
    const config = metricsConfig[metric]
    const name = config?.name ?? ''
    const getSeriesChartTypeWhenOriginalTypeIsBoth = (idx: number): string =>
        idx === 0 ? COLUMN : SPLINE
    const marker = {
        lineColor: '#FFFFFF',
        lineWidth: 1.5,
        enabled: false,
        states: {
            hover: {
                radius: 5,
            },
        },
        symbol: markerSymbol,
    }
    if (!markerSymbol) {
        delete marker.symbol
    }
    const series: any[] = [
        {
            name,
            data: (widgetData ?? []).map((record) => [
                moment.utc(record[groupedBy]).valueOf(),
                record[metric],
            ]),
            yAxis: yAxisIdx,
            color,
            marker,
            // custom properties available on series.options object
            // update TimeSeriesPointOptions type if adding more fields
            metric,
            side,
            isPriorPeriod: false,
            isColumn: widget.chart_type === COLUMN,
            type:
                chartType === SPLINE_AND_COLUMN
                    ? getSeriesChartTypeWhenOriginalTypeIsBoth(seriesIdx)
                    : chartType,
            legendItemBorderColor,
            showlegendSymbol:
                widget.metrics_by_axis?.showSymbolsInLegend && !!markerSymbol,
        },
    ]
    if (priorPeriodConfig.hasData) {
        series.push({
            name,
            // adjust the date to match the corresponding date of
            // the current series so they line up on the chart
            data: (widgetPriorPeriodData ?? []).map((record) => [
                moment(record[groupedBy])
                    .add(priorPeriodConfig.diff, priorPeriodConfig.unit)
                    .valueOf(),
                record[metric],
            ]),
            yAxis: yAxisIdx,
            color: priorPeriodColor,
            borderColor: priorPeriodBorderColor,
            dashStyle: 'Dash',
            marker: {
                enabled: false,
                fillColor: '#FFFFFF',
                lineWidth: 2,
                symbol: markerSymbol,
            },
            // custom properties available on series.options object
            // update TimeSeriesPointOptions type if adding more fields
            metric,
            side,
            isPriorPeriod: true,
            isColumn: widget.chart_type === COLUMN,
            type: chartType === SPLINE_AND_COLUMN ? COLUMN : chartType,
            legendItemBorderColor,
            showlegendSymbol:
                widget.metrics_by_axis?.showSymbolsInLegend && !!markerSymbol,
        })
    }
    return series
}

export const generateGroupedSeries = (
    widgetGroupBy: string[],
    metric: string,
    widgetDataSource: '' | NonTextWidgetDataSourceKey,
    widgetData: any[],
    automationCapabilitiesMap: any,
    brandGroupsMap: any,
    chartType: string,
    metricsConfig: FieldMapping,
    colorPalette?: ColorPalette
): any[] => {
    // Time aggregate and secondary grouping selected so group
    // the data by the secondary grouping and map through these
    // collections building a series config object for each.
    const [timeAggregate, grouping] = widgetGroupBy
    const groupByConfig =
        DATA_SOURCES[widgetDataSource]?.groupByConfig?.[grouping]
    const groupedMap = groupBy(widgetData, grouping)

    const config = metricsConfig[metric]
    const metricOrder = isRankMetric(config) ? 'asc' : 'desc'

    // Push "Others" to the end then sort by metric mean
    const orderedGroupedMap = orderBy(
        groupedMap,
        [
            (group) => group[0][grouping] === 'Others',
            (group) => meanBy(group, (record) => record[metric]),
        ],
        ['asc', metricOrder]
    )

    return orderedGroupedMap.map((data, index) => {
        const name = getSeriesName(data[0], data[0][grouping], groupByConfig, {
            automationCapabilitiesMap,
        })

        const seriesData = data.map((record) => [
            moment(record[timeAggregate]).valueOf(),
            record[metric],
        ])

        return {
            name: truncate(name, { length: 60 }),
            data: seriesData,
            color: getSeriesColor(
                data[0],
                index,
                name,
                groupByConfig,
                colorPalette,
                { brandGroupsMap }
            ),
            type: chartType === SPLINE_AND_COLUMN ? SPLINE : chartType,
            metric,
        }
    })
}
