import { interpolateRainbow } from 'd3-scale-chromatic'
import get from 'lodash/get'

import { CODED_COLORS } from 'const/colors'
import { hashCode } from 'helpers/hashCode'

const stringToDecimal = (str: string, integrity = 450): number => {
    const hash = hashCode(str)
    const getDecimal = (n: number): number =>
        parseFloat(((hash % n) / n).toFixed(4))
    return getDecimal(integrity)
}

/**
 * Generates a color value from any string
 * @param str string to be converted
 * @param colorScheme optional color scheme to choose a color from
 * @param integrity optional number of values to split the color scheme into. Higher numbers yield more granular color options, lower numbers yield more contrast between colors.
 */
export const stringToColor = (
    str: string,
    colorScheme = interpolateRainbow,
    integrity = 450
): string => {
    const strLower = str.toLowerCase()
    const codedColor = CODED_COLORS[strLower]
    if (codedColor) {
        return codedColor
    }

    const DECIMAL: number = stringToDecimal(str, integrity)
    return colorScheme(DECIMAL)
}

export const propToColor = (
    record: any,
    pathToProp: string | number | symbol | [string | number | symbol],
    defaultValue?: unknown,
    colorScheme = interpolateRainbow,
    integrity = 450
): string => {
    const prop = get(record, pathToProp, defaultValue)
    return stringToColor(`${prop}`, colorScheme, integrity)
}

export function calculateUsageColor(
    usage: number,
    limit: number,
    defaultColor: string
): string {
    const usagePercentage = (usage / limit) * 100
    if (usagePercentage >= 89 && usagePercentage < 99) {
        return '#F9C402' // yellow
    }
    if (usagePercentage >= 99) {
        return '#F41E28' // red
    }
    return defaultColor
}
