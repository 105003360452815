/**
 * Helper for widgets that define several axes at both sides of the graph.
 * After DOW-10016. New widgets always use this helper.
 */

import { YAxisOptions } from 'highcharts'

import { Y_AXIS_PRIOR_PERIOD_COLUMN_COLORS } from 'configuration/charts'
import { COLUMN } from 'const/widgets'
import { elementToString } from 'helpers/utilities'
import { transformToString } from 'helpers/widgets'
import {
    AutomationCapability,
    AxisConfiguration,
    FieldMapping,
    PriorPeriodConfig,
    Widget,
} from 'types'

import {
    generateGroupedSeries,
    generateMetricSeries,
    getAxisByMetric,
    getFinalChartType,
} from './utils'

interface AxisDefinition {
    isRightSide: boolean
    sideIdx: number
    conf: AxisConfiguration
}

export class AxisDrivenSeries {
    private readonly widget: Widget

    private readonly widgetData: any[]

    private readonly widgetPriorPeriodData: any[]

    private readonly widgetGroupBy: string[]

    private readonly automationCapabilitiesMap: Record<
        string,
        AutomationCapability
    >

    private readonly brandGroupsMap: Record<string, any>

    private readonly priorPeriodConfig: PriorPeriodConfig

    private readonly metricsConfig: FieldMapping

    private numberOfMetricsOnTheRight = 0

    private numberOfMetricsOnTheLeft = 0

    private allAxesDefined: AxisDefinition[]

    constructor(
        widget: Widget,
        widgetData: any[],
        widgetPriorPeriodData: any[],
        priorPeriodConfig: PriorPeriodConfig,
        automationCapabilitiesMap: any,
        brandGroupsMap: any,
        metricsConfig: FieldMapping
    ) {
        this.widget = widget
        this.widgetData = widgetData
        this.widgetPriorPeriodData = widgetPriorPeriodData
        this.widgetGroupBy = transformToString(widget.group_by) ?? []
        this.automationCapabilitiesMap = automationCapabilitiesMap
        this.brandGroupsMap = brandGroupsMap
        this.priorPeriodConfig = priorPeriodConfig
        this.metricsConfig = metricsConfig

        const mapSideToAxisDefinitions = (
            isRightSide: boolean,
            axes: AxisConfiguration[]
        ): AxisDefinition[] => {
            let numberOfMetricsOnThisSide = 0
            const definitionsOnThisSide = axes
                .filter((axis) => axis?.metrics?.length)
                .map((axis, idx) => {
                    numberOfMetricsOnThisSide += axis.metrics.length
                    return {
                        isRightSide,
                        conf: axis,
                        sideIdx: idx,
                    }
                })
            if (isRightSide) {
                this.numberOfMetricsOnTheRight = numberOfMetricsOnThisSide
            } else {
                this.numberOfMetricsOnTheLeft = numberOfMetricsOnThisSide
            }
            return definitionsOnThisSide
        }

        let axes: AxisDefinition[] = []
        if (this.widget.metrics_by_axis?.left) {
            axes = mapSideToAxisDefinitions(
                false,
                this.widget.metrics_by_axis.left
            )
        }
        if (this.widget.metrics_by_axis?.right) {
            axes = axes.concat(
                mapSideToAxisDefinitions(
                    true,
                    this.widget.metrics_by_axis.right
                )
            )
        }

        if (priorPeriodConfig.hasData) {
            this.numberOfMetricsOnTheLeft *= 2
            this.numberOfMetricsOnTheRight *= 2
        }

        this.allAxesDefined = [...axes]
    }

    getYAxes = (showTitle: boolean = true): YAxisOptions[] =>
        this.allAxesDefined.map((axis) => {
            // Let's assume that all the metrics in the axis can be formatted similarly
            const sampleMetric = axis.conf.metrics[0].metric
            const useTitle = showTitle && axis.conf.metrics.length === 1

            return getAxisByMetric({
                widget: this.widget,
                metric: sampleMetric,
                useTitle,
                color:
                    this.widgetGroupBy.length === 1
                        ? axis.conf.axisColor
                        : '#434348',
                isRightAxis: axis.isRightSide,
                metricsConfig: this.metricsConfig,
                verticalTitle: axis.sideIdx > 0,
                title: useTitle
                    ? elementToString(
                          this.metricsConfig[sampleMetric]?.name ?? '-'
                      )
                    : undefined,
            })
        })

    getSeries = (): any[] => {
        if (this.widgetGroupBy.length === 1) {
            let seriesIdx = -1
            return this.allAxesDefined
                .flatMap((axis, axisIdx) =>
                    axis.conf.metrics.map((metric) => ({
                        metricConf: metric,
                        axisConf: axis,
                        axisIdx,
                    }))
                )
                .sort((a, b) => {
                    if (a.metricConf.chartType === b.metricConf.chartType) {
                        return 0
                    }
                    return a.metricConf.chartType === COLUMN ? -1 : 1
                })
                .flatMap((metricDefinition) => {
                    seriesIdx += 1
                    const metric = metricDefinition.metricConf
                    const axis = metricDefinition.axisConf
                    const { axisIdx } = metricDefinition
                    const lineColor = metric.color
                    const yAxisColor = axis.conf.axisColor
                    return generateMetricSeries({
                        widget: this.widget,
                        widgetData: this.widgetData,
                        widgetPriorPeriodData: this.widgetPriorPeriodData,
                        groupedBy: this.widgetGroupBy[0],
                        metric: metric.metric,
                        chartType: getFinalChartType(
                            metric.chartType,
                            this.widget.stacked
                        ),
                        color: lineColor,
                        yAxisIdx: axisIdx,
                        seriesIdx,
                        priorPeriodConfig: this.priorPeriodConfig,
                        priorPeriodColor:
                            metric.chartType === COLUMN
                                ? Y_AXIS_PRIOR_PERIOD_COLUMN_COLORS[
                                      axisIdx %
                                          Y_AXIS_PRIOR_PERIOD_COLUMN_COLORS.length
                                  ]
                                : lineColor,
                        priorPeriodBorderColor: lineColor,
                        metricsConfig: this.metricsConfig,
                        side: axis.isRightSide ? 'right' : 'left',
                        legendItemBorderColor: this.widget.metrics_by_axis
                            ?.addBorderToLegendItems
                            ? yAxisColor
                            : undefined,
                        markerSymbol: this.widget.metrics_by_axis
                            ?.showSymbolsInLegend
                            ? metric.symbol
                            : undefined,
                    })
                })
        }

        if (this.widgetGroupBy.length === 2) {
            // We can assume that, if there is an additional grouping, there is only a single metric
            return generateGroupedSeries(
                this.widgetGroupBy,
                this.allAxesDefined[0].conf.metrics[0].metric,
                this.widget.data_source,
                this.widgetData,
                this.automationCapabilitiesMap,
                this.brandGroupsMap,
                getFinalChartType(
                    this.allAxesDefined[0].conf.metrics[0].chartType,
                    this.widget.stacked
                ),
                this.metricsConfig,
                this.widget.color_palette
            )
        }

        return []
    }

    getLegendOptions = (legend: any): any => {
        const estimatedLines = Math.floor(
            Math.max(
                this.numberOfMetricsOnTheLeft,
                this.numberOfMetricsOnTheRight
            ) / 4
        )
        return {
            ...legend,
            doSplit: this.widget.metrics_by_axis?.splitLegendItemsPerYAxis,
            considerSymbols: this.widget.metrics_by_axis?.showSymbolsInLegend,
            width: this.widget.metrics_by_axis?.splitLegendItemsPerYAxis
                ? '100%'
                : legend.width,
            padding: this.widget.metrics_by_axis?.splitLegendItemsPerYAxis
                ? 8 + estimatedLines * 10
                : legend.padding,
            symbolHeight: this.widget.metrics_by_axis?.showSymbolsInLegend
                ? 10
                : legend.symbolHeight,
            symbolWidth: this.widget.metrics_by_axis?.showSymbolsInLegend
                ? 10
                : legend.symbolWidth,
            estimatedLines,
        }
    }
}
