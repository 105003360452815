import { TFunction } from 'i18next'

import { isString } from 'helpers/typeGuard'
import { BooleanFilter, BooleanFilterOption, LabelFilterOption } from 'types'

export const AGGREGATION = 'aggregation'
export const AUTOMATIONS = 'automations'
export const ADVANCED_AUTOMATIONS = 'advancedAutomations'
export const AUTOMATION_PROFILE_NAME = 'automationProfileName'
export const AUTOMATION_PROFILES = 'automationProfiles'

export const ASIN_FILTER_LIMIT = 100
export const SKU_FILTER_LIMIT = 100
export const SEGMENT_ASIN_FILTER_LIMIT = 20_000
export const DSP_AUDIENCE_FILTER_LIMIT = 10
export const DSP_CREATIVE_FILTER_LIMIT = 10

export const AD_ACCOUNT_TYPES = 'adAccountTypes'

export const BRANDS = 'brands'
export const ORGANIZATIONS = 'organizations'

export const PORTFOLIOS = 'portfolios'

export const CAMPAIGN_BUDGET = 'campaignBudget'
export const CAMPAIGN_BUDGET_TYPES = 'campaignBudgetTypes'
export const CAMPAIGN_NAME = 'campaignName'
export const CAMPAIGN_STATES = 'campaignStates'
export const CAMPAIGN_START_DATE = 'campaignStartDate'
export const CAMPAIGN_TARGETING_TYPES = 'campaignTargetingTypes'
export const CAMPAIGN_AD_FORMATS = 'campaignAdFormats'
export const CAMPAIGNS = 'campaigns'

export const CATALOG_ASINS = 'catalogAsins'

export const AD_GROUPS = 'adGroups'
export const AD_GROUP_STATES = 'adGroupStates'

export const AD_ITEMS = 'adItems'
export const AD_ITEM_STATES = 'adItemStates'

export const AD_ACCOUNTS = 'adAccounts'

export const BID_MULTIPLIERS = 'bidMultipliers'

export const COUNTRIES = 'countries'
export const REGIONS = 'regions'

export const DASHBOARD_NAME = 'dashboardName'

export const SEGMENT_NAME = 'segmentName'
export const SEGMENT_ASIN_INCLUDES = 'segmentAsinIncludes'
export const SEGMENT_ASIN_EXCLUDES = 'segmentAsinExcludes'
export const SEGMENT_PRICE_MIN_MAX = 'segmentPriceMinMax'
export const SEGMENT_REVIEWS_MIN_MAX = 'segmentReviewsMinMax'
export const SEGMENT_REVENUE_MIN_MAX = 'segmentRevenueMinMax'
export const SEGMENT_SALES_MIN_MAX = 'segmentSalesMinMax'
export const SEGMENT_CATEGORY = 'segmentCategory'

export const DATES = 'dates'
export const RANGE_LAG = 'rangeLag'

export const FACT_TYPES = 'factTypes'

export const KEYWORD_STATES = 'keywordStates'
export const KEYWORD_TEXT = 'keywordText'
export const KEYWORD_TEXT_EXACT = 'keywordTextExact'
export const KEYWORD_MATCH_TYPES = 'keywordMatchTypes'
export const KEYWORDS = 'keywords'

export const NEGATIVE_KEYWORD_TEXT = 'negativeKeywordText'
export const PARENT_ASIN = 'parentAsin'
export const PRODUCT_TITLE = 'productTitle'
export const PRODUCT_ASINS = 'productAsins'
export const PRODUCT_SKUS = 'productSkus'
export const PRODUCT_TITLES = 'productTitles'
export const PRODUCT_NUMBER_OF_ITEMS = 'productNumberOfItems'
export const PRODUCT_ADS = 'productAds'
export const PRODUCT_AD_STATES = 'productAdStates'
export const PARENT_ASINS = 'parentAsins'

export const LABELS = 'labels'
export const LABEL_TEXT = 'labelText'

export const ADVANCED_TEXT = 'advancedText'

export const METRIC_FILTERS = 'metricFilters'

export const SEARCH_TERM = 'searchTerm'
export const SEARCH_TERM_NAME = 'searchTermName'
export const SOV_PLATFORMS = 'sovPlatforms'
export const SOV_COUNTRIES = 'sovCountries'
export const SOV_BRANDS = 'sovBrands'
export const SOV_FOLDS = 'sovFolds'
export const SOV_KEYWORDS = 'sovKeywords'
export const SOV_LANGUAGES = 'sovLanguages'
export const SOV_RESULT_TYPES = 'sovResultTypes'
export const SOV_STATES = 'sovStates'
export const SOV_BRANDS_LIMIT = 'sovBrandsLimit'
export const SOV_BRANDS_WEIGHT = 'sovBrandsWeight'
export const SOV_ASINS = 'sovAsins'
export const SOV_METADATA_BRANDS = 'sovMetadataBrands'
export const SOV_RANK = 'sovRank'
export const SOV_ORGANIC_RANK = 'sovOrganicRank'
export const SOV_SPONSORED_RANK = 'sovSponsoredRank'
export const SOV_OVERALL_RANK = 'sovOverallRank'
export const SOV_LAYOUT = 'sovLayout'
export const SOV_WORD_COUNT = 'sovWordCount'
export const SOV_AD_PRESENCE = 'sovAdPresence'
export const SOV_EXACT_SEARCH_VOLUME = 'sovExactSearchVolume'
export const SOV_BROAD_SEARCH_VOLUME = 'sovBroadSearchVolume'
export const SOV_SEASONALITY = 'sovSeasonality'
export const SOV_RELEVANCY_SCORE = 'sovRelevancyScore'
export const SOV_VALUE_SCORE = 'sovValueScore'
export const SOV_DOMINANT_CATEGORIES = 'sovCategories'
export const SOV_EASE_TO_RANK = 'sovEaseToRank'

export const SC_ASINS = 'scAsins'
export const SC_PRODUCT_TITLES = 'scProductTitles'
export const SC_ACCOUNTS = 'scAccounts'

export const VC_INTEGRATIONS = 'vendorCentralIntegrations'
export const VC_PROGRAMS = 'vendorCentralPrograms'

export const RESOURCE_TYPES = 'resourceTypes'

export const RULEBOOK_NAME = 'rulebookName'
export const RULEBOOK_STATES = 'rulebookStates'
export const CHANGE_SET_STATES = 'changeSetStates'

export const TARGETS = 'targets'
export const TARGET_STATES = 'targetStates'
export const TARGET_TEXT = 'targetText'
export const TARGET_SEARCH_TEXT = 'targetSearchText'

export const CUSTOM_EVENT_DESCRIPTION = 'customEventDescription'

// DSP
export const DSP_ADVERTISERS = 'dspAdvertisers'
export const DSP_ORDERS = 'dspOrders'
export const DSP_LINE_ITEMS = 'dspLineItems'
export const DSP_AUDIENCE_NAME = 'dspAudience'
export const DSP_AUDIENCE_ID = 'dspAudienceId'
export const DSP_CREATIVE_NAME = 'dspCreativeName'
export const DSP_CREATIVE_ID = 'dspCreativeId'
export const DSP_CREATIVE_TYPE = 'dspCreativeType'

export const WM_CAMPAIGNS = 'wmCampaigns'
export const WM_KEYWORDS = 'wmKeywords'
export const WM_ADVERTISING_GROUPS = 'wmAdGroups'
export const WM_ADVERTISING_ITEMS = 'wmAdItems'

// SOV AD PRESENCE

export const AD_PRESENCE_NOT_ADVERTISING = 'not_advertising'
export const AD_PRESENCE_WITH_AD_KEYWORDS = 'with_ad_keywords'
export const AD_PRESENCE_WITH_AD_SEARCH_TERMS = 'with_ad_search_terms'

// Segments
export const SEGMENTS = 'segments'
export const ADVANCED_PRODUCT_TITLE = 'productTitleText'

// Keyword Research Lists
export const KEYWORD_RESEARCH_LIST_NAME = 'keywordResearchListName'
export const KEYWORD_RESEARCH_LIST = 'keywordResearchLists'

export const EQUALS = 'exact'
export const GREATER_THAN = 'gt'
export const LESS_THAN = 'lt'
export const GREATER_THAN_OR_EQUAL = 'gte'
export const LESS_THAN_OR_EQUAL = 'lte'
export const RANK_WORSE_THAN = 'rank_gt'
export const RANK_WORSE_THAN_OR_EQUAL = 'rank_gte'
export const RANK_BETTER_THAN_OR_EQUAL = 'rank_lte'

export const CONTAINS = '_icontains'
export const DOES_NOT_CONTAIN = '_not_icontains'
export const STARTS_WITH = '_starts_with'
export const ENDS_WITH = '_ends_with'
export const INSENSITIVE_STARTS_WITH = '_istarts_with'
export const INSENSITIVE_ENDS_WITH = '_iends_with'
export const EXACT = '_exact'
export const INSENSITIVE_EXACT = '_iexact'

export const NUMBER_RANGE_OPERATOR_OPTIONS = (
    t: TFunction
): BooleanFilterOption[] => [
    {
        label: t('filters:NumberRangeControl.operatorLabels.equals', 'Equals'),
        value: EQUALS,
    },
    {
        label: t(
            'filters:NumberRangeControl.operatorLabels.gt',
            'Greater Than'
        ),
        value: GREATER_THAN,
    },
    {
        label: t('filters:NumberRangeControl.operatorLabels.lt', 'Less Than'),
        value: LESS_THAN,
    },
    {
        label: t(
            'filters:NumberRangeControl.operatorLabels.gte',
            'Greater Than or Equal To'
        ),
        value: GREATER_THAN_OR_EQUAL,
    },
    {
        label: t(
            'filters:NumberRangeControl.operatorLabels.lte',
            'Less Than or Equal To'
        ),
        value: LESS_THAN_OR_EQUAL,
    },
    {
        value: RANK_BETTER_THAN_OR_EQUAL,
        label: t(
            'filters:NumberRangeControl.operatorLabels.rankBetterThan',
            'In the top'
        ),
    },
    {
        value: RANK_WORSE_THAN_OR_EQUAL,
        label: t(
            'filters:NumberRangeControl.operatorLabels.rankWorseThan',
            'Not in the top'
        ),
    },
    {
        value: RANK_WORSE_THAN,
        label: t(
            'filters:NumberRangeControl.operatorLabels.rankWorseThan',
            'Not in the top'
        ),
    },
]

// Auto Filters
export const TOP_AGGREGATE_FILTER = 'top_aggregate_filter'
export const DEFAULT_AUTO_FILTER_DIRECTION = 'desc'
export const DEFAULT_AUTO_FILTER_LIMIT = 10
export const AUTO_FILTER_DIRECTIONS = [
    { value: 'desc', label: 'Top' },
    { value: 'asc', label: 'Bottom' },
]

// Boolean Filters
export const AND_SUFFIX = '_id_and_in'
export const OR_SUFFIX = '_id_in'
export const NOT_OR_SUFFIX = '_id_not_in'

export const NONE_SUFFIX = '_has_none'

export const COUNT_EQUALS_SUFFIX = '_id_count_eq'
export const COUNT_GREATER_THAN_SUFFIX = '_id_count_gt'
export const COUNT_LESS_THAN_SUFFIX = '_id_count_lt'

export const GREATER_THAN_SUFFIX = '_gt'
export const LESS_THAN_SUFFIX = '_lt'
export const EQUALS_SUFFIX = '_eq'

export const NUMBER_RANGE_OPERATOR_DISPLAY: { [operator: string]: string } = {
    [EQUALS]: '=',
    [GREATER_THAN]: '>',
    [LESS_THAN]: '<',
    [GREATER_THAN_OR_EQUAL]: '≥',
    [LESS_THAN_OR_EQUAL]: '≤',
    [COUNT_GREATER_THAN_SUFFIX]: '>',
    [COUNT_LESS_THAN_SUFFIX]: '<',
    [GREATER_THAN_SUFFIX]: '>',
    [LESS_THAN_SUFFIX]: '<',
    [EQUALS_SUFFIX]: '=',
    [RANK_BETTER_THAN_OR_EQUAL]: 'Top',
    [RANK_WORSE_THAN_OR_EQUAL]: 'Not in the top',
    [RANK_WORSE_THAN]: 'Not in the top',
}

export const DEFAULT_VALUE_FOR_OPERATOR: {
    [operator: string]: [] | undefined
} = {
    [OR_SUFFIX]: [],
    [AND_SUFFIX]: [],
    [NOT_OR_SUFFIX]: [],
    [NONE_SUFFIX]: [],
    [COUNT_GREATER_THAN_SUFFIX]: undefined,
    [COUNT_LESS_THAN_SUFFIX]: undefined,
    [COUNT_EQUALS_SUFFIX]: undefined,
    [GREATER_THAN]: undefined,
    [LESS_THAN]: undefined,
    [EQUALS]: undefined,
    [CONTAINS]: [],
    [DOES_NOT_CONTAIN]: [],
    [STARTS_WITH]: [],
    [INSENSITIVE_STARTS_WITH]: [],
    [ENDS_WITH]: [],
    [INSENSITIVE_ENDS_WITH]: [],
    [EXACT]: undefined,
    [INSENSITIVE_EXACT]: undefined,
}

export const BOOLEAN_OPERATOR_LABELS = (
    t: TFunction
): { [key: string]: string } => ({
    [AND_SUFFIX]: t(
        'filters:BooleanControl.operatorLabels.and',
        'Contains all of'
    ),
    [OR_SUFFIX]: t(
        'filters:BooleanControl.operatorLabels.or',
        'Contains any of'
    ),
    [NOT_OR_SUFFIX]: t(
        'filters:BooleanControl.operatorLabels.notOr',
        'Does not contain any of'
    ),
    [NONE_SUFFIX]: t(
        'filters:BooleanControl.operatorLabels.none',
        'Contains none'
    ),
})

const NONE_OPERATOR = (t: TFunction): BooleanFilterOption => ({
    label: t('filters:BooleanControl.operatorLabels.none', 'Contains none'),
    value: NONE_SUFFIX,
})

const COMMON_OPERATOR_OPTIONS = (t: TFunction): BooleanFilterOption[] => [
    {
        label: t('filters:BooleanControl.operatorLabels.or', 'Contains any of'),
        value: OR_SUFFIX,
    },
    {
        label: t(
            'filters:BooleanControl.operatorLabels.and',
            'Contains all of'
        ),
        value: AND_SUFFIX,
    },
    {
        label: t(
            'filters:BooleanControl.operatorLabels.notOr',
            'Does not contain any of'
        ),
        value: NOT_OR_SUFFIX,
    },
]

export const BOOLEAN_OPERATOR_OPTIONS = (
    t: TFunction
): BooleanFilterOption[] => [...COMMON_OPERATOR_OPTIONS(t), NONE_OPERATOR(t)]

export const LABEL_FILTER_OPERATOR_OPTIONS = (
    t: TFunction
): BooleanFilterOption[] => [
    ...COMMON_OPERATOR_OPTIONS(t),
    {
        label: t(
            'filters:BooleanControl.operatorLabels.countLessThan',
            'Count is less than'
        ),
        value: COUNT_LESS_THAN_SUFFIX,
    },
    {
        label: t(
            'filters:BooleanControl.operatorLabels.countGreaterThan',
            'Count is greater than'
        ),
        value: COUNT_GREATER_THAN_SUFFIX,
    },
    {
        label: t(
            'filters:BooleanControl.operatorLabels.countEquals',
            'Count is exactly'
        ),
        value: COUNT_EQUALS_SUFFIX,
    },
    NONE_OPERATOR(t),
]

export const BID_MULTIPLIER_FILTER_OPERATOR_OPTIONS = (
    t: TFunction
): BooleanFilterOption[] => [
    {
        label: t(
            'filters:BooleanControl.operatorLabels.greaterThan',
            'Greater Than'
        ),
        value: GREATER_THAN_SUFFIX,
    },
    {
        label: t('filters:BooleanControl.operatorLabels.lessThan', 'Less Than'),
        value: LESS_THAN_SUFFIX,
    },
    {
        label: t('filters:BooleanControl.operatorLabels.equals', 'Equals'),
        value: EQUALS_SUFFIX,
    },
]

export const ADVANCED_TEXT_OPERATOR_OPTIONS = (
    t: TFunction,
    caseSensitive: boolean
): BooleanFilterOption[] => [
    {
        label: t('filters:BooleanControl.operatorText.contains', 'Contains'),
        value: CONTAINS,
    },
    {
        label: t(
            'filters:BooleanControl.operatorText.doesNotContain',
            'Does not contain'
        ),
        value: DOES_NOT_CONTAIN,
    },
    {
        label: t(
            'filters:BooleanControl.operatorText.startsWith',
            'Starts with'
        ),
        value: caseSensitive ? STARTS_WITH : INSENSITIVE_STARTS_WITH,
    },
    {
        label: t('filters:BooleanControl.operatorText.endsWith', 'Ends with'),
        value: caseSensitive ? ENDS_WITH : INSENSITIVE_ENDS_WITH,
    },
]

export const ADVANCED_TEXT_OPERATOR_OPTIONS_WITH_EXACT = (
    t: TFunction,
    caseSensitive: boolean
): BooleanFilterOption[] => [
    ...ADVANCED_TEXT_OPERATOR_OPTIONS(t, caseSensitive),
    {
        label: t('filters:BooleanControl.operatorText.exact', 'Is exactly'),
        value: caseSensitive ? EXACT : INSENSITIVE_EXACT,
    },
]

export const ADVANCED_TEXT_OPERATOR_OPTIONS_OPEN_SEARCH = (
    t: TFunction,
    caseSensitive: boolean
): BooleanFilterOption[] => [
    {
        label: t('filters:BooleanControl.operatorText.contains', 'Contains'),
        value: CONTAINS,
    },
    {
        label: t(
            'filters:BooleanControl.operatorText.doesNotContain',
            'Does not contain'
        ),
        value: DOES_NOT_CONTAIN,
    },
    {
        label: t('filters:BooleanControl.operatorText.exact', 'Is exactly'),
        value: caseSensitive ? EXACT : INSENSITIVE_EXACT,
    },
]

export const RESOURCE_TYPE_CAMPAIGN_NAME = 'campaign__name'
const RESOURCE_TYPE_PORTFOLIO_NAME = 'portfolio__name'
const RESOURCE_TYPE_AD_GROUP_NAME = 'ad_group__name'
export const RESOURCE_TYPE_KEYWORD_TEXT = 'keyword__text'
export const RESOURCE_TYPE_SEARCH_TERM = 'query'
export const RESOURCE_TYPE_PRODUCT_TITLE = 'product_ad__metadata__title'
const RESOURCE_TYPE_PRODUCT_METADATA_BRAND_NAME = 'brand'
const RESOURCE_TYPE_PRODUCT_METADATA_BREADCRUMB_TREE = 'breadcrumb_tree'
export const RESOURCE_TYPE_PRODUCT_METADATA_TITLE = 'title'

export const BOOLEAN_TEXT_RESOURCE_TYPE_OPTIONS_DEFAULT = (
    t: TFunction
): BooleanFilterOption[] => [
    {
        label: t(
            'filters:BooleanControl.resourceType.campaignName',
            'Campaign Name'
        ),
        value: RESOURCE_TYPE_CAMPAIGN_NAME,
    },
    {
        label: t(
            'filters:BooleanControl.resourceType.portfolioName',
            'Portfolio Name'
        ),
        value: RESOURCE_TYPE_PORTFOLIO_NAME,
    },
]

export const BOOLEAN_TEXT_RESOURCE_TYPE_OPTIONS_AD_GROUP = (
    t: TFunction
): BooleanFilterOption[] =>
    BOOLEAN_TEXT_RESOURCE_TYPE_OPTIONS_DEFAULT(t).concat([
        {
            label: t(
                'filters:BooleanControl.resourceType.adGroupName',
                'Ad Group Name'
            ),
            value: RESOURCE_TYPE_AD_GROUP_NAME,
        },
    ])

export const BOOLEAN_TEXT_RESOURCE_TYPE_OPTIONS_KEYWORD = (
    t: TFunction
): BooleanFilterOption[] =>
    BOOLEAN_TEXT_RESOURCE_TYPE_OPTIONS_AD_GROUP(t).concat([
        {
            label: t(
                'filters:BooleanControl.resourceType.keywordText',
                'Keyword Text'
            ),
            value: RESOURCE_TYPE_KEYWORD_TEXT,
        },
    ])

export const BOOLEAN_TEXT_RESOURCE_TYPE_OPTIONS_KEYWORD_SEARCH_TERM = (
    t: TFunction
): BooleanFilterOption[] =>
    BOOLEAN_TEXT_RESOURCE_TYPE_OPTIONS_KEYWORD(t).concat([
        {
            label: t(
                'filters:BooleanControl.resourceType.searchTerm',
                'Search Term'
            ),
            value: RESOURCE_TYPE_SEARCH_TERM,
        },
    ])

export const BOOLEAN_TEXT_RESOURCE_TYPE_OPTIONS_PRODUCT = (
    t: TFunction
): BooleanFilterOption[] =>
    BOOLEAN_TEXT_RESOURCE_TYPE_OPTIONS_DEFAULT(t).concat([
        {
            label: t(
                'filters:BooleanControl.resourceType.productTitle',
                'Product Title'
            ),
            value: RESOURCE_TYPE_PRODUCT_TITLE,
        },
    ])

export const BOOLEAN_TEXT_RESOURCE_TYPE_OPTIONS_PRODUCT_METADATA = (
    t: TFunction
): BooleanFilterOption[] => [
    {
        label: t(
            'filters:BooleanControl.resourceType.productTitle',
            'Product Title'
        ),
        value: RESOURCE_TYPE_PRODUCT_METADATA_TITLE,
    },
    {
        label: t(
            'filters:BooleanControl.resourceType.productMetadata.brandName',
            'Brand Name'
        ),
        value: RESOURCE_TYPE_PRODUCT_METADATA_BRAND_NAME,
    },
    {
        label: t(
            'filters:BooleanControl.resourceType.productMetadata.categories',
            'Categories'
        ),
        value: RESOURCE_TYPE_PRODUCT_METADATA_BREADCRUMB_TREE,
    },
]

export const getBooleanFilterValue = (
    currentFilterValue: LabelFilterOption
): BooleanFilter => {
    // Empty state or old format
    if (!currentFilterValue || currentFilterValue.label) {
        return {}
    }

    return isString(currentFilterValue.value)
        ? JSON.parse(currentFilterValue.value)
        : currentFilterValue.value
}

export enum HierarchicalFilterState {
    OVERRIDDEN_BY_WIDGET = 'overriddenByWidget',
    APPLIED = 'applied',
    NOT_APPLICABLE = 'notApplicable',
    OVERRIDES_TAB_VALUES = 'overridesTabValues',
    APPLIED_BY_TAB = 'appliedByTab',
    APPLIED_BY_WIDGET = 'appliedByWidget',
}

export const RESOURCE_NAME_BY_FILTER_KEY: {
    [key: string]: string
} = {
    [ADVANCED_AUTOMATIONS]: 'Automation',
    [ADVANCED_PRODUCT_TITLE]: 'Product Title',
    [ADVANCED_TEXT]: 'Advanced Text Search',
    [AD_ACCOUNTS]: 'Ad Account',
    [AD_ACCOUNT_TYPES]: 'Ad Account Types',
    [AUTOMATIONS]: 'Automation',
    [BRANDS]: 'Ad Account',
    [ORGANIZATIONS]: 'Client',
    [CAMPAIGNS]: 'Campaign',
    [CAMPAIGN_AD_FORMATS]: 'Campaign Ad Format',
    [CAMPAIGN_STATES]: 'Campaign State',
    [CAMPAIGN_START_DATE]: 'Campaign Start Date',
    [CAMPAIGN_TARGETING_TYPES]: 'Campaign Targeting Type',
    [COUNTRIES]: 'Country',
    [DATES]: 'Date Range',
    [DSP_ADVERTISERS]: 'Advertiser',
    [DSP_LINE_ITEMS]: 'Line Item',
    [DSP_ORDERS]: 'Order',
    [DSP_AUDIENCE_NAME]: 'Audience Name',
    [DSP_AUDIENCE_ID]: 'Audience Id',
    [DSP_CREATIVE_NAME]: 'Creative Name',
    [DSP_CREATIVE_ID]: 'Creative Id',
    [DSP_CREATIVE_TYPE]: 'Creative Type',
    [FACT_TYPES]: 'Ad Type',
    [LABELS]: 'Label',
    [METRIC_FILTERS]: 'Metric',
    [KEYWORDS]: 'Ad Keyword',
    [KEYWORD_MATCH_TYPES]: 'Keyword Match Type',
    [PORTFOLIOS]: 'Portfolio',
    [PRODUCT_ADS]: 'Product Ad',
    [PRODUCT_AD_STATES]: 'Product Ad State',
    [PRODUCT_ASINS]: 'ASIN',
    [PRODUCT_SKUS]: 'SKU',
    [PRODUCT_NUMBER_OF_ITEMS]: 'Pack Size',
    [PRODUCT_TITLES]: 'Product Title',
    [REGIONS]: 'Region',
    [RESOURCE_TYPES]: 'Resource Type',
    [SC_ASINS]: 'ASIN',
    [SC_ACCOUNTS]: 'Integration',
    [SC_PRODUCT_TITLES]: 'Product Title',
    [SEARCH_TERM]: 'Search Term',
    [SEGMENTS]: 'Segment',
    [SOV_ASINS]: 'ASIN',
    [SOV_BRANDS]: 'Brand',
    [SOV_DOMINANT_CATEGORIES]: 'Dominant Category',
    [SOV_COUNTRIES]: 'Country',
    [SOV_FOLDS]: 'Fold',
    [SOV_KEYWORDS]: 'Tracked Keyword',
    [SOV_LANGUAGES]: 'Language',
    [SOV_LAYOUT]: 'Layout Columns',
    [SOV_METADATA_BRANDS]: 'Brand',
    [SOV_PLATFORMS]: 'Platform',
    [SOV_RANK]: 'Rank',
    [SOV_RESULT_TYPES]: 'Search Result Type',
    [SOV_STATES]: 'Tracked Keyword State',
    [VC_PROGRAMS]: 'Platform',
    [VC_INTEGRATIONS]: 'Integration',
}
