import { addCache } from 'helpers/utilities'
import { ApiFuncResponse } from 'hooks/useTypeahead'
import { axiosInstanceOrganizations } from 'services/cerebroApi/orgScope/client'
import {
    BannerRequestBody,
    CerebroApiOptions,
    CerebroPaginatedResponse,
    CerebroResourceResponse,
    OrganizationMonthlyAsinUsageSummary,
    ProductMetadata,
    Report,
    Segment,
    SegmentModelVersion,
} from 'types'

import { getCerebroHeaders } from './headers'

export const getSegment = (
    id: string
): Promise<CerebroResourceResponse<Segment>> =>
    axiosInstanceOrganizations.get(`/api/segment/${id}/`)

export const createSegment = (
    data: Partial<Segment>
): Promise<CerebroResourceResponse<Segment>> =>
    axiosInstanceOrganizations.post('/api/segments/', data)

export const updateSegment = (
    id: string,
    data: Partial<Segment>
): Promise<CerebroResourceResponse<Segment>> =>
    axiosInstanceOrganizations.patch(`/api/segment/${id}/`, data)

type AggregationItem = {
    id: string
    label: string
    name: string
    count: number
}

interface Aggregations {
    meta: {
        options_limit: number
    }
    distinct_parent_categories: number | null
    distinct_sub_categories: number | null
    distinct_brands: number | null
    brands: AggregationItem[]
    categories: AggregationItem[]
}

export const getSegmentConfigPreview = (
    { data, params }: { data: object; params: object },
    options: CerebroApiOptions = {}
): Promise<
    CerebroPaginatedResponse<ProductMetadata> & {
        aggregations: Aggregations
    }
> =>
    axiosInstanceOrganizations.post('/api/segments/products_preview/', data, {
        params,
        headers: getCerebroHeaders(options.headers),
    })

export const getSegmentFacts = (
    params: BannerRequestBody,
    options: CerebroApiOptions = {}
): Promise<CerebroPaginatedResponse<any>> =>
    axiosInstanceOrganizations.post(
        '/api/segment_asin_sales_estimate_segmentizer_facts/aggregate/banner/',
        params,
        {
            headers: getCerebroHeaders(options.headers),
        }
    )
addCache(getSegmentFacts)

export const getSegmentFactsExport = (
    params: BannerRequestBody
): Promise<CerebroResourceResponse<Report>> =>
    axiosInstanceOrganizations.post(
        `/api/segment_asin_sales_estimate_segmentizer_facts/aggregate/banner/export/`,
        params
    )

export const getSegmentBrands = (
    segmentId: string,
    params: unknown,
    options: CerebroApiOptions = {}
): ApiFuncResponse<any> =>
    axiosInstanceOrganizations.get(`/api/segment/${segmentId}/brands/`, {
        params,
        headers: getCerebroHeaders(options.headers),
    })
addCache(getSegmentBrands)

export const getSegmentsBrands = (
    params: unknown,
    options: CerebroApiOptions = {}
): Promise<
    CerebroPaginatedResponse<{ id: string; label: string; count: number }>
> =>
    axiosInstanceOrganizations.get(`/api/segments/brands/`, {
        params,
        headers: getCerebroHeaders(options.headers),
    })
addCache(getSegmentsBrands)

export const getSegmentProductMetadataAsinTitles = (
    segmentId: string,
    params: unknown,
    options: CerebroApiOptions = {}
): ApiFuncResponse<any> =>
    axiosInstanceOrganizations.get(`/api/segment/${segmentId}/asin_titles/`, {
        params,
        headers: getCerebroHeaders(options.headers),
    })
addCache(getSegmentProductMetadataAsinTitles)

export const getSegmentStatus = (
    segmentId: string,
    options: CerebroApiOptions = {}
): Promise<CerebroResourceResponse<Partial<Segment>>> =>
    axiosInstanceOrganizations.get(`/api/segment/${segmentId}/status/`, {
        headers: getCerebroHeaders(options.headers),
    })

export const getSegmentLoaded = (
    segmentId: string,
    options: CerebroApiOptions = {}
): Promise<CerebroResourceResponse<Partial<Segment>>> =>
    axiosInstanceOrganizations.get(`/api/segment/${segmentId}/loaded/`, {
        headers: getCerebroHeaders(options.headers),
    })

export const getMonthlySegmentsAsinUsage = (
    period?: string
): Promise<CerebroResourceResponse<OrganizationMonthlyAsinUsageSummary>> => {
    return axiosInstanceOrganizations.get('/api/segments/monthly_asin_usage/', {
        params: { period },
    })
}

/**
 * Updates the status of a segment between paused and active
 *
 * @param segmentId id of the segment
 * @param options
 */
export const toggleSegmentStatus = (
    segmentId: string,
    options: CerebroApiOptions = {}
): Promise<CerebroResourceResponse<Partial<Segment>>> =>
    axiosInstanceOrganizations.patch(
        `/api/segment/${segmentId}/pause/`,
        {},
        {
            headers: getCerebroHeaders(options.headers),
        }
    )

export const getSegmentModelVersions = (): Promise<
    CerebroPaginatedResponse<SegmentModelVersion>
> => axiosInstanceOrganizations.get('/api/segments/model_versions/')
