export interface SerializableOrgGroup {
    orgId: string
    orgGroupId: string
    label?: string
}

export const serializeOrgGroup = (
    serializable: SerializableOrgGroup
): string => {
    return JSON.stringify([serializable.orgId, serializable.orgGroupId])
}

export const deserializeOrgGroup = (
    serializedValue: string
): SerializableOrgGroup => {
    const [orgId, orgGroupId] = JSON.parse(serializedValue)
    return {
        orgId,
        orgGroupId,
    }
}

export const buildSerializableOrgGroupLabel = (
    orgName?: string,
    orgGroupName?: string,
    orgAgencyName?: string
): string => {
    if (!orgName || !orgGroupName) {
        return 'Loading...'
    }
    const orgAgency = orgAgencyName ? ` | ${orgAgencyName}` : ''
    return `${orgName}${orgAgency} | ${orgGroupName}`
}
