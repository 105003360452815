import { Organization, User } from 'types'

export const registerPendoUser = (
    user: User,
    org: Organization | null
): void => {
    const { username, email, first_name, last_name } = user

    // Hide the resource center whenever the user clicks outside it
    // See: https://github.com/pendo-io/snippets/blob/master/resourceCenter/dismissRCWhenClickOutsideTargetElementRC/dismissRCWhenClickOutsideTargetElementRC.js
    const events = {
        events: {
            guidesLoaded: () => {
                const hideResourceCenterOffClick = (e: any): void => {
                    const tgt = e.target
                    const path = e.composedPath()
                    let resourceCenterDescendant = false
                    for (let i = 0; i < path.length; i += 1) {
                        if (path[i].id === 'pendo-resource-center-container') {
                            resourceCenterDescendant = true
                        }
                    }
                    if (
                        window.pendo.dom('#pendo-resource-center-container')
                            .length &&
                        !resourceCenterDescendant &&
                        !window.pendo
                            .dom(tgt)
                            .closest(
                                window.pendo.BuildingBlocks.BuildingBlockResourceCenter.getResourceCenter()
                                    .steps[0].elementPathRule
                            ).length
                    ) {
                        window.pendo.BuildingBlocks.BuildingBlockResourceCenter.dismissResourceCenter()
                    }
                }

                window.pendo.attachEvent(
                    document,
                    'click',
                    hideResourceCenterOffClick
                )
            },
        },
    }

    const visitor = {
        visitor: {
            id: username,
            email,
            first_name,
            last_name,
            domain: window.location.host,
            languageBrowser: window.navigator?.language,
        },
    }

    const account = (): any => {
        if (!org) {
            return {}
        }
        const agency =
            org?.agency && typeof org?.agency === 'object' ? org.agency : null

        return {
            account: {
                id: `${STAGE}-${org.id}`,
                name: org.name,
                billing_entity_id: `${STAGE}-${agency ? agency.id : org.id}`,
                billing_entity_name: agency?.name ?? org.name,
            },
        }
    }

    const pendoInfo = {
        ...events,
        ...visitor,
        ...account(),
    }

    if (window.pendo?.visitorId === username) {
        window.pendo.identify(pendoInfo)
        return
    }

    window.pendo.initialize(pendoInfo)
}

export const registerFullstoryUser = (user: User): void => {
    const { username: id, email, first_name, last_name } = user

    window.FS?.identify(id, {
        email,
        first_name,
        last_name,
    })
}
