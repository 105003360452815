import {
    ReactElement,
    ReactNode,
    RefObject,
    useEffect,
    useRef,
    useState,
} from 'react'

import { Badge, Button, Row } from 'antd'
import classNames from 'classnames'
import some from 'lodash/some'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { NavLink } from 'react-router-dom'

import {
    toggleBulkUpdatesAppDrawer,
    toggleReportsAppDrawer,
    unmountAppDrawer,
} from 'actions/ui/app'
import { useAppConfig } from 'appConfig'
import { AppBreadcrumb } from 'components/AppBreadcrumb'
import { FeatureStatusTag } from 'components/FeatureStatusTag'
import { userHasDashboardsOnlyPermissions } from 'helpers/featurePermissions'
import { useAction, useUserHasPermissions } from 'hooks/redux'
import {
    AsyncUpdate,
    Breadcrumb,
    Report,
    RootReduxState,
    TriggeredAlert,
} from 'types'

import { AlertsDrawer, ReportsDrawer, UpdatesDrawer } from './Drawers'
import { GetHelpMenu, ProfileMenu } from './Dropdowns'
import StickyHeader from './StickyHeader'
import styles from './styles.scss'
import {
    BellIcon,
    InsightsIconOutlined,
    ReportIcon,
    UploadIcon,
} from '../Icons'

interface PageHeaderProps {
    elevated?: boolean
    color?: 'transparent' | 'white'
    newReports: Report[]
    drawerReports: Report[]
    drawerReportsLoading: boolean
    newUpdates: AsyncUpdate[]
    drawerUpdates: AsyncUpdate[]
    drawerUpdatesLoading: boolean
    triggeredAlerts: TriggeredAlert[]
    signOutRequest: () => void
    fetchReportsForDrawerRequest: () => void
    fetchUpdatesForDrawerRequest: () => void
    fetchTriggeredAlertsForDrawerRequest: () => void
    downloadReport: (payload: { reportId: string }) => void
    breadcrumbs?: Breadcrumb[]
    titleElement?: ReactElement
    filterGroupElement?: ReactElement
    stickyHeaderElement?: ReactElement
    stickyHeaderThresholdRef?: RefObject<HTMLDivElement>
    filterOverlay?: string
    userEmail: string
}

const PageHeader = ({
    elevated = false,
    color = 'white',
    newReports,
    drawerReports,
    drawerReportsLoading,
    newUpdates,
    drawerUpdates,
    drawerUpdatesLoading,
    signOutRequest,
    fetchReportsForDrawerRequest,
    fetchUpdatesForDrawerRequest,
    fetchTriggeredAlertsForDrawerRequest,
    downloadReport,
    filterGroupElement,
    stickyHeaderElement,
    stickyHeaderThresholdRef,
    breadcrumbs = [],
    titleElement,
    filterOverlay,
    triggeredAlerts,
    userEmail,
}: PageHeaderProps): ReactElement => {
    const { t } = useTranslation('common')
    const { isChinaBuild } = useAppConfig()

    const unmountDrawer = useAction(unmountAppDrawer)
    const filtersRef = useRef<HTMLDivElement>(null)

    const dashboardOnlyExperience = useUserHasPermissions(
        userHasDashboardsOnlyPermissions
    )

    // Report drawer state management
    const isReportsDrawerOpen = useSelector(
        (state: RootReduxState) => state.ui.app.drawerReports.isVisible
    )
    const toggleReportsDrawer = useAction(toggleReportsAppDrawer)
    useEffect(() => {
        if (isReportsDrawerOpen) {
            fetchReportsForDrawerRequest()
        }
    }, [fetchReportsForDrawerRequest, isReportsDrawerOpen])

    const handleClickReportsButton = (): void => {
        toggleReportsDrawer(!isReportsDrawerOpen)
    }
    const handleCloseReportsDrawer = (): void => {
        unmountDrawer()
        toggleReportsDrawer(false)
    }

    // Updates drawer state management
    const isUpdatesDrawerOpen = useSelector(
        (state: RootReduxState) => state.ui.app.drawerUpdates.isVisible
    )
    const toggleBulkUpdatesDrawer = useAction(toggleBulkUpdatesAppDrawer)
    useEffect(() => {
        if (isUpdatesDrawerOpen) {
            fetchUpdatesForDrawerRequest()
        }
    }, [fetchUpdatesForDrawerRequest, isUpdatesDrawerOpen])

    const handleClickUpdatesButton = (): void => {
        toggleBulkUpdatesDrawer(!isUpdatesDrawerOpen)
    }
    const handleCloseUpdatesDrawer = (): void => {
        unmountDrawer()
        toggleBulkUpdatesDrawer(false)
    }

    // Alerts drawer state management
    const [isAlertsDrawerOpen, setIsAlertsDrawerOpen] = useState(false)

    const handleClickAlertsButton = (): void => {
        fetchTriggeredAlertsForDrawerRequest()
        setIsAlertsDrawerOpen((open) => !open)
    }
    const handleCloseAlertsDrawer = (): void => {
        unmountDrawer()
        setIsAlertsDrawerOpen(false)
    }

    // Sticky header
    const renderStickyHeader = (): ReactElement | null => {
        const thresholdElement = (stickyHeaderThresholdRef || filtersRef)
            .current

        if (!stickyHeaderElement || !thresholdElement) {
            return null
        }

        const scrollTop = thresholdElement.offsetTop

        return (
            <StickyHeader topOffset={scrollTop}>
                {stickyHeaderElement}
            </StickyHeader>
        )
    }

    const renderAlertsNavItem = (): ReactNode => {
        const hasUnreadAlerts = some(triggeredAlerts, ['status', 'unread'])

        const AlertsButton = (
            <Button
                type="link"
                className="fg-text-muted"
                onClick={handleClickAlertsButton}
            >
                <Badge dot={hasUnreadAlerts}>
                    <BellIcon />
                </Badge>
                <span className="ml-2">{t('common:alerts', 'Alerts')}</span>
            </Button>
        )

        return AlertsButton
    }

    return (
        <>
            <div
                className={classNames('px-4', styles['wrapper-position'], {
                    [styles['color-white']]: color === 'white',
                    [styles.elevated]: elevated,
                })}
            >
                <div
                    className="d-flex justify-content-between pt-3"
                    style={{
                        paddingBottom: '5px',
                    }}
                >
                    <AppBreadcrumb items={breadcrumbs} />

                    <ul className={styles['header-nav']}>
                        {!dashboardOnlyExperience && (
                            <li className={styles['new-feature-highlight']}>
                                <FeatureStatusTag
                                    status="new"
                                    style={{
                                        backgroundColor: '#5561f6',
                                        color: 'white',
                                        display: 'flex',
                                        alignItems: 'center',
                                        fontSize: '10px',
                                        height: '17px',
                                    }}
                                />
                                <NavLink
                                    className={(isActive) =>
                                        !isActive
                                            ? 'fg-text-muted ml-1'
                                            : 'ml-1'
                                    }
                                    to="/insights"
                                >
                                    <InsightsIconOutlined />
                                    <span className="ml-2">Insights</span>
                                </NavLink>
                            </li>
                        )}
                        {!dashboardOnlyExperience && (
                            <li>{renderAlertsNavItem()}</li>
                        )}
                        <li>
                            <Button
                                type="link"
                                className="fg-text-muted ant-btn ant-btn-link"
                                onClick={handleClickReportsButton}
                            >
                                <ReportIcon />
                                <span>{t('common:reports', 'Reports')}</span>
                                <Badge count={newReports.length} />
                            </Button>
                        </li>
                        {!dashboardOnlyExperience && (
                            <li>
                                <Button
                                    type="link"
                                    className="fg-text-muted ant-btn ant-btn-link"
                                    onClick={handleClickUpdatesButton}
                                >
                                    <UploadIcon />
                                    <span>
                                        {t(
                                            'common:bulkUpdates',
                                            'Bulk Updates'
                                        )}
                                    </span>
                                    <Badge count={newUpdates.length} />
                                </Button>
                            </li>
                        )}
                        {!isChinaBuild && (
                            <li>
                                <GetHelpMenu />
                            </li>
                        )}
                        <li>
                            <ProfileMenu
                                signOutRequest={signOutRequest}
                                email={userEmail}
                            />
                        </li>
                    </ul>
                </div>

                {titleElement && <Row>{titleElement}</Row>}

                {filterGroupElement && (
                    <div ref={filtersRef} className={styles['filters-wrap']}>
                        {filterOverlay && (
                            <div className={styles['filters-overlay']}>
                                {filterOverlay}
                            </div>
                        )}

                        {filterGroupElement}
                    </div>
                )}
            </div>

            {renderStickyHeader()}

            {/* Alerts Drawer */}
            <AlertsDrawer
                triggeredAlerts={triggeredAlerts}
                visible={isAlertsDrawerOpen}
                onClose={handleCloseAlertsDrawer}
            />

            {/* Reports Drawer */}
            <ReportsDrawer
                newReports={newReports}
                drawerReports={drawerReports}
                drawerReportsLoading={drawerReportsLoading}
                downloadReport={downloadReport}
                visible={isReportsDrawerOpen}
                onClose={handleCloseReportsDrawer}
            />

            {/* Updates Drawer */}
            <UpdatesDrawer
                drawerUpdates={drawerUpdates}
                drawerUpdatesLoading={drawerUpdatesLoading}
                visible={isUpdatesDrawerOpen}
                onClose={handleCloseUpdatesDrawer}
            />
        </>
    )
}

export default PageHeader
