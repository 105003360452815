import { ReactElement } from 'react'

import Highcharts, { XAxisOptions } from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import truncate from 'lodash/truncate'
import { useSelector } from 'react-redux'

import { DEFAULT_TITLE, DEFAULT_TOOLTIP } from 'configuration/charts'
import { DATA_SOURCES } from 'configuration/widgets'
import { BAR, BUBBLE, COLUMN, PIE } from 'const/widgets'
import {
    DEFAULT_HORIZONTAL_LEGEND,
    DEFAULT_X_AXIS,
    getYAxisOptions,
} from 'helpers/chart'
import {
    formatDataLabel,
    formatLegendItem,
    getSeriesColor,
    getSeriesName,
} from 'helpers/charts'
import { formatNumber } from 'helpers/formatting'
import { elementToString } from 'helpers/utilities'
import { transformToString } from 'helpers/widgets'
import {
    selectAutomationCapabilitiesMap,
    selectBrandGroupsMap,
} from 'selectors/ui'
import { FieldMapping, Widget } from 'types'

import { chartTooltipFormatter } from './helpers/tooltip'

interface Props {
    widget: Widget
    widgetData: any[]
    metricsConfig: FieldMapping
}

function CategoryChartWidget({
    widget,
    widgetData,
    metricsConfig,
}: Props): ReactElement {
    const automationCapabilitiesMap = useSelector(
        selectAutomationCapabilitiesMap
    )
    const brandGroupsMap = useSelector(selectBrandGroupsMap)

    const widgetMetrics = transformToString(widget.metrics)
    const metric = widgetMetrics[0]
    const groupBy = transformToString(widget.group_by)[0]

    const dataSource = DATA_SOURCES[widget.data_source]
    const groupByConfigMap = dataSource?.groupByConfig
    const groupByConfig = groupByConfigMap ? groupByConfigMap[groupBy] : {}

    const metricConfig = metricsConfig[metric]

    const countTypes = ['count', 'currencyAmount']
    const showPercent = metricConfig?.metricOptions?.type
        ? countTypes.includes(metricConfig.metricOptions.type)
        : false

    const getPercentage = (percentage: number): string | undefined => {
        if ((!widget.chart_type || widget.chart_type === PIE) && showPercent) {
            return formatNumber(percentage / 100, '0%')
        }
        return undefined
    }

    const YAxisName =
        widget.chart_type === BUBBLE
            ? elementToString(metricsConfig[widgetMetrics[0]]?.shortName ?? '')
            : elementToString(metricConfig?.name ?? '')

    return (
        <HighchartsReact
            immutable
            highcharts={Highcharts}
            options={{
                chart: {
                    type: widget.chart_type || PIE,
                    marginTop: widget.chart_type === COLUMN ? 50 : undefined,
                    marginBottom: widget.show_legend === false ? 70 : undefined,
                },
                legend: {
                    ...DEFAULT_HORIZONTAL_LEGEND,
                    squareSymbol: false,
                    labelFormatter() {
                        const { visible, name, color } = this as any
                        return formatLegendItem(
                            visible,
                            elementToString(name),
                            color,
                            undefined,
                            undefined,
                            widget.chart_type !== BAR
                        )
                    },
                },
                plotOptions: {
                    pie: {
                        dataLabels: {
                            useHTML: true,
                            formatter() {
                                const { point, key, percentage } = this as any
                                const formattedValue = formatNumber(
                                    point.y,
                                    metricConfig?.metricOptions?.shortFormat
                                )

                                // Assuming getPercentage(percentage) returns the percentage string
                                // Wrap the percentage in a span with a style for the color red
                                const formattedPercentage =
                                    getPercentage(percentage)
                                const percentageText = formattedPercentage
                                    ? `<span style="color: inherit;">${formattedPercentage}</span>`
                                    : ''

                                return formatDataLabel(
                                    point.color,
                                    truncate(elementToString(key)),
                                    formattedValue,
                                    percentageText // Pass the styled percentage text
                                )
                            },
                        },
                        showInLegend: true,
                        innerSize: '50%',
                    },
                    column: {
                        showInLegend: false,
                    },
                    bubble: {
                        showInLegend: false,
                        innerSize: '50%',
                    },
                    series: {
                        animation: false,
                    },
                    bar: {
                        showInLegend: widget.show_legend !== false,
                        innerSize: '50%',
                    },
                },
                series: [
                    {
                        name:
                            widget.chart_type === BAR
                                ? elementToString(metricConfig?.name ?? '')
                                : undefined,
                        data: widgetData
                            .map((record, recordIndex) => {
                                const name = elementToString(
                                    getSeriesName(
                                        record,
                                        record[groupBy],
                                        groupByConfig,
                                        { automationCapabilitiesMap }
                                    )
                                )
                                const dataPoints = {
                                    x: undefined,
                                    y: record[metric],
                                    z: undefined,
                                }
                                if (widget.chart_type === BUBBLE) {
                                    if (widgetMetrics.length > 1) {
                                        dataPoints.x = record[widgetMetrics[1]]
                                    }
                                    if (widgetMetrics.length > 2) {
                                        dataPoints.z = record[widgetMetrics[2]]
                                    }
                                }
                                return {
                                    name,
                                    ...dataPoints,
                                    color: getSeriesColor(
                                        record,
                                        recordIndex,
                                        name,
                                        groupByConfig,
                                        widget.color_palette,
                                        { brandGroupsMap }
                                    ),
                                }
                            })
                            .toSorted(
                                (a, b) =>
                                    (a.name === 'Others' ? 1 : 0) -
                                    (b.name === 'Others' ? 1 : 0)
                            ),
                    },
                ],
                title: DEFAULT_TITLE,
                tooltip: {
                    ...DEFAULT_TOOLTIP,
                    formatter() {
                        const { point } = this as any
                        return chartTooltipFormatter({
                            point,
                            widget,
                            metricsConfig,
                        })
                    },
                },
                xAxis: ((): XAxisOptions => {
                    if ((widget.chart_type as string) === BUBBLE) {
                        return {
                            ...DEFAULT_X_AXIS,
                            title: {
                                text: elementToString(
                                    metricsConfig[widgetMetrics[1]]
                                        ?.shortName ?? ''
                                ),
                                style: {
                                    whiteSpace: 'nowrap',
                                },
                            },
                        }
                    }
                    return {
                        ...DEFAULT_X_AXIS,
                        type: 'category',
                        labels:
                            widget.chart_type === BAR
                                ? { style: { color: '#aaa' } }
                                : DEFAULT_X_AXIS.labels,
                    }
                })(),
                yAxis: getYAxisOptions({
                    titleText:
                        widget.chart_type === BAR ? undefined : YAxisName,
                    verticalTitle: (widget.chart_type as any) === BUBBLE,
                    color: '#aaa',
                    valueFormatter: (value) => {
                        let formattedValue = value

                        if (metricConfig) {
                            formattedValue = formatNumber(
                                value,
                                metricConfig?.metricOptions?.shortFormat
                            )
                        }

                        return `<span style="color: #aaa;">${formattedValue}</span>`
                    },
                }),
            }}
        />
    )
}

export default CategoryChartWidget
